import {
  BarsArrowDownIcon,
  BarsArrowUpIcon,
} from '@heroicons/react/24/outline';
import {
  AdjustmentsHorizontalIcon,
  ArchiveBoxXMarkIcon,
  MagnifyingGlassCircleIcon,
} from '@heroicons/react/24/solid';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import {
  Button,
  Collapse,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Switch,
} from '@mui/material';
import SelectAutocomplete from 'Components/Common/SelectAutocomplete/SelectAutocomplete';
import { useCatalogStore } from 'Features/Core/logic/store/catalog';
import CommonModal from 'Features/CreateTemplatesForm/Components/CommonModal';
import useThemeStore from 'Theme/store';
import { isEqual } from 'lodash';
import { inject, observer } from 'mobx-react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { InView } from 'react-intersection-observer';
import { useLocation, useNavigate } from 'react-router-dom';
import { debounce } from 'utils/debounce';
import { cn } from 'utils/styles';
import ActiveFilterItem from './Components/ActiveFilterItem';
import MiniTab from './Components/MiniTab';
import TabIcon from './Components/TabIcon';
import TemplateCard, { LoadingTemplateCard } from './Components/TemplateCard';
import { CATEGORIES_ICONS, CATEGORY_MAP_NAMES } from './constants';
import usePromptLibraryStore from './store';

const PromptLibrary = ({ store }) => {
  const { catalog } = useCatalogStore();
  // Prompt Library
  const { setOpenPromptLibrary, openPromptLibrary } = usePromptLibraryStore();
  const handleClose = () => {
    // Delete url param to close the prompt library
    const urlParams = new URLSearchParams(location.search);
    urlParams.delete('from');
    navigate(
      {
        pathname: location.pathname,
        search: urlParams.toString(),
      },
      { shallow: true }
    );
    setOpenPromptLibrary(false);
  };
  // Theme
  const { theme } = useThemeStore();
  // Collapse state browse
  const [openBrowse, setOpenBrowse] = useState(false);
  const onToggleBrowse = () => setOpenBrowse(!openBrowse);
  // Collapse state filters
  const [openFilters, setOpenFilters] = useState(false);
  const onToggleFilters = () => setOpenFilters(!openFilters);

  // Form
  const { register, setValue, watch, reset } = useForm({
    models: [],
    createdBy: [],
    withAttachments: false,
    categories: [],
    folders: [],
    search: '',
    sort: 'runs',
  });

  //----------------------------------------GET PROMPT LIBRARY----------------------------------------
  const [tools, setTools] = useState([]);
  const [meta, setMeta] = useState({
    total: 0,
    page: 1,
    current: 1,
  });
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);

  const getPromptLibrary = async (values) => {
    setLoading(true);
    try {
      const response = await store.api.get(`/tool/prompt-library`, {
        params: {
          creator:
            values?.createdBy?.length > 0 ? values?.createdBy.join(',') : null,
          keyword: values?.search !== '' ? values?.search : null,
          model: values?.models?.length > 0 ? values?.models.join(',') : null,
          parent_folder:
            values?.folders?.length > 0 ? values?.folders.join(',') : null,
          category:
            values?.categories?.length > 0
              ? values?.categories.join(',')
              : null,
          has_files: values?.withAttachments === true ? 'true' : 'false',
          page: values.page ?? 1,
          limit: 25,
          sort_by:
            values?.sort === 'recent'
              ? 'created_at'
              : values?.sort === 'runs'
              ? 'runs'
              : 'alphabetical',
        },
      });
      const data = await response.data;

      if (data != null) {
        setTools((prev) => [...prev, ...data.tools]);
        setMeta({
          total: data.totalCount,
          page: data.totalPages,
          current: data.currentPage,
        });
      }
    } catch (error) {
      toast.error('Error loading prompt library');
    } finally {
      setLoading(false);
    }
  };

  const onChangeLimit = (inView) => {
    if (inView && meta.current < meta.page) {
      setPage(page + 1);
    }
  };

  const prevValuesRef = useRef({});

  const getCurrentValues = useCallback(
    () => ({
      createdBy: watch('createdBy'),
      categories: watch('categories'),
      folders: watch('folders'),
      models: watch('models'),
      withAttachments: watch('withAttachments'),
      sort: watch('sort'),
      search: watch('search'),
      page,
    }),
    [watch, page]
  );

  const getPromptLibraryDebounce = useRef(
    debounce((values, currentPage) => {
      prevValuesRef.current = { ...values, page: currentPage };
      getPromptLibrary({ ...values, page: currentPage });
    }, 500)
  ).current;

  useEffect(() => {
    const currentValues = getCurrentValues();
    const previousValues = prevValuesRef.current;

    const filtersChanged = !isEqual(
      {
        createdBy: currentValues.createdBy,
        categories: currentValues.categories,
        folders: currentValues.folders,
        models: currentValues.models,
        withAttachments: currentValues.withAttachments,
        sort: currentValues.sort,
        search: currentValues.search,
      },
      {
        createdBy: previousValues.createdBy,
        categories: previousValues.categories,
        folders: previousValues.folders,
        models: previousValues.models,
        withAttachments: previousValues.withAttachments,
        sort: previousValues.sort,
        search: previousValues.search,
      }
    );

    if (filtersChanged) {
      setPage(1);
      setTools([]);
      getPromptLibraryDebounce(currentValues, 1);
    } else if (currentValues.page !== previousValues.page) {
      getPromptLibraryDebounce(currentValues, currentValues.page);
    }

    prevValuesRef.current = currentValues;
  }, [
    watch('createdBy'),
    watch('categories'),
    watch('folders'),
    watch('models'),
    watch('withAttachments'),
    watch('sort'),
    watch('search'),
    page,
    openPromptLibrary,
  ]);

  const handleSearch = () => {
    const currentValues = getCurrentValues();
    getPromptLibrary(currentValues);
  };
  //-----------------------------------------------------------------------------------------------------

  //----------------------------------------SELECT TOOL AND OPEN----------------------------------------
  const location = useLocation();
  const navigate = useNavigate();
  const onSelectTemplate = async (toolId) => {
    // set url param to open the tool
    const urlParams = await new URLSearchParams(location.search);
    urlParams.set('selectedTool', toolId);
    urlParams.set('from', 'promptLibrary');
    await navigate(
      {
        pathname: location.pathname,
        search: urlParams.toString(),
      },
      { shallow: true }
    );
  };
  //-----------------------------------------------------------------------------------------------------

  const [sort, setSort] = useState('asc');
  const onToggleSort = (e, sort) => {
    e.stopPropagation();
    setSort(sort);
  };

  const memoTemplateFolders = useMemo(() => {
    if (sort === 'asc') {
      return catalog?.templateFolders?.sort((a, b) =>
        a.name.localeCompare(b.name)
      );
    } else {
      return catalog?.templateFolders?.sort((a, b) =>
        b.name.localeCompare(a.name)
      );
    }
  }, [catalog?.templateFolders, sort]);

  const onClearFilters = () => {
    reset({
      models: [],
      createdBy: [],
      withAttachments: false,
      categories: [],
      folders: [],
      search: '',
    });
    handleSearch();
  };

  return (
    <CommonModal
      withCloseButton
      open={openPromptLibrary}
      handleClose={handleClose}
      scroll="paper"
      withoutContainer
      maxWidth="xl"
      fullWidth
      withoutPadding
      sx={{
        height: { md: 'calc(100% - 8px)', xs: '100dvh' },
      }}
    >
      <DialogTitle
        className={cn('flex flex-col w-full gap-3 font-figtree', {
          'text-crystal-bell bg-lead': theme === 'dark',
          'text-raisin-black bg-seasalt': theme !== 'dark',
        })}
      >
        <h1 className="text-2xl font-bold">Prompt Template Library</h1>
        <div className="flex gap-2 items-center">
          <input
            type="search"
            style={{ '--color': theme !== 'dark' ? '#000' : '#fff' }}
            placeholder="Enter a keyword or category"
            className={cn(
              'py-2 px-3 shadow-md appearance-none text-base focus:outline-none transition flex flex-1 w-full rounded-xl border-2 placeholder-cool-gray',
              {
                'bg-white text-raisin-black border-platinum shadow-black/25 ':
                  theme !== 'dark',
                'bg-night-black text-crystal-bell border-palladium shadow-white/25':
                  theme === 'dark',
              }
            )}
            autoComplete="off"
            {...register('search')}
          />
          <IconButton onClick={onToggleFilters}>
            <AdjustmentsHorizontalIcon
              className={cn('w-7 h-7 ', {
                'text-raisin-black': theme !== 'dark',
                'text-crystal-bell': theme === 'dark',
              })}
            />
          </IconButton>
        </div>
        <Collapse in={openFilters} timeout="auto" unmountOnExit>
          <div className={cn('w-full')}>
            <div className="flex w-full justify-between flex-col md:flex-row">
              <div className="flex w-full gap-4 flex-col md:flex-row">
                <div className="max-w-[400px] min-w-[250px] flex gap-2 items-center">
                  <label
                    className={cn('capitalize md:text-base text-xs font-bold', {
                      'text-crystal-bell': theme === 'dark',
                      'text-raisin-black': theme !== 'dark',
                    })}
                  >
                    Models:
                  </label>
                  <SelectAutocomplete
                    placeholderSearch="Search LLMs"
                    value={catalog?.models
                      ?.filter((i) => watch('models')?.includes(i._id))
                      ?.map((i) => ({
                        name: i.name,
                        id: i._id,
                      }))}
                    onChange={(value) => {
                      setValue('models', [...value.map((i) => i.id)]);
                    }}
                    options={catalog?.models.map((i) => ({
                      name: i.name,
                      id: i._id,
                    }))}
                  />
                </div>
                <div className="max-w-[400px] min-w-[250px] flex gap-2 items-center">
                  <label
                    className={cn('capitalize md:text-base text-xs font-bold', {
                      'text-crystal-bell': theme === 'dark',
                      'text-raisin-black': theme !== 'dark',
                    })}
                  >
                    Created by:
                  </label>
                  <SelectAutocomplete
                    placeholderSearch="Search creators"
                    value={catalog?.createdBy?.filter((i) =>
                      watch('createdBy')?.includes(i.id)
                    )}
                    onChange={(value) => {
                      setValue('createdBy', [...value.map((i) => i.id)]);
                    }}
                    options={catalog?.createdBy ?? []}
                  />
                </div>
                <div>
                  <label
                    className={cn('capitalize md:text-base text-xs font-bold', {
                      'text-crystal-bell': theme === 'dark',
                      'text-raisin-black': theme !== 'dark',
                    })}
                  >
                    With attachments:
                  </label>
                  <Switch
                    checked={Boolean(watch('withAttachments'))}
                    {...register('withAttachments')}
                  />
                </div>
              </div>
              <div className="flex w-full flex-1 justify-end md:justify-normal">
                <Button
                  sx={{
                    width: 'max-content',
                  }}
                  color="inherit"
                  varian="text"
                  onClick={onClearFilters}
                >
                  Clear filters
                </Button>
              </div>
            </div>
          </div>
        </Collapse>
      </DialogTitle>
      <div
        className={cn('h-2 w-full', {
          'bg-gradient-to-b to-transparent from-white': theme !== 'dark',
          'bg-gradient-to-b to-transparent from-umbra': theme === 'dark',
        })}
        content=" "
      ></div>
      <DialogContent
        className={cn('flex-1 overflow-hidden flex-col flex w-full px-9 py-6', {
          'bg-white': theme !== 'dark',
          'bg-lead': theme === 'dark',
        })}
      >
        {/* TITLE */}
        <div className="flex flex-col w-full">
          <div className={cn('pb-4 flex items-center justify-between')}>
            <h1
              className={cn(
                'font-bold text-sm md:text-base font-figtree uppercase',
                {
                  'text-crystal-bell': theme === 'dark',
                  'text-raisin-black': theme !== 'dark',
                }
              )}
            >
              {meta.total} {meta?.total > 1 ? 'results' : 'result'}
            </h1>
            <div className="flex items-center gap-2">
              <label
                className={cn('font-figtree font-bold text-sm capitalize', {
                  'text-crystal-bell': theme === 'dark',
                  'text-raisin-black': theme !== 'dark',
                })}
              >
                Sort by:
              </label>
              <select
                {...register('sort')}
                className={cn(
                  'font-figtree font-normal text-xs border px-2 rounded-md',
                  {
                    'border-platinum bg-ghost-white': theme !== 'dark',
                    'border-sonic-silver bg-palladium': theme === 'dark',
                  }
                )}
              >
                <option value="runs">Most used</option>
                <option value="alphabetically">Alphabetically</option>
                <option value="recent">Recently added</option>
              </select>
            </div>
          </div>
          {/* ACTIVE FILTERS */}
          <div className="flex flex-wrap items-start justify-start overflow-auto h-full pb-3 gap-[8px]">
            {catalog?.createdBy
              ?.filter((i) => watch('createdBy')?.includes(i.id))
              ?.map((by, index) => {
                return (
                  <ActiveFilterItem
                    key={index}
                    text={by?.name}
                    onDelete={() => {
                      const users = watch('createdBy') ?? [];
                      if (users?.includes(by.id)) {
                        setValue(
                          'createdBy',
                          users.filter((i) => i !== by.id)
                        );
                      }
                    }}
                  />
                );
              })}
            {catalog?.models
              ?.filter((i) => watch('models')?.includes(i._id))
              .map((model, index) => {
                return (
                  <ActiveFilterItem
                    key={index}
                    text={model.name}
                    onDelete={() => {
                      const models = watch('models') ?? [];
                      if (models?.includes(model._id)) {
                        setValue(
                          'models',
                          models.filter((i) => i !== model._id)
                        );
                      }
                    }}
                  />
                );
              })}
            {watch('categories')?.map((category, index) => {
              const lowerCaseCategory = category.toLowerCase();
              const localName =
                CATEGORY_MAP_NAMES[lowerCaseCategory] || lowerCaseCategory;

              return (
                <ActiveFilterItem
                  key={index}
                  text={localName}
                  onDelete={() => {
                    const categories = watch('categories') ?? [];
                    if (categories?.includes(lowerCaseCategory)) {
                      setValue(
                        'categories',
                        categories.filter((i) => i !== lowerCaseCategory)
                      );
                    }
                  }}
                />
              );
            })}
            {catalog?.templateFolders
              ?.filter((i) => watch('folders')?.includes(i._id))
              .map((folder, index) => {
                return (
                  <ActiveFilterItem
                    key={index}
                    text={folder.name}
                    onDelete={() => {
                      const folders = watch('folders') ?? [];
                      if (folders.includes(folder._id)) {
                        setValue(
                          'folders',
                          folders.filter((i) => i !== folder._id)
                        );
                      }
                    }}
                  />
                );
              })}
            {watch('withAttachments') && (
              <>
                <ActiveFilterItem
                  text="With Attachments"
                  onDelete={() => {
                    setValue('withAttachments', false);
                  }}
                />
              </>
            )}
          </div>
        </div>
        {/* TEMPLATES */}
        <div
          className={cn(
            'flex flex-wrap items-start gap-4  px-5 overflow-auto h-full overflow-rtl',
            {
              'justify-start': tools?.lenght === 1,
              'justify-evenly': tools?.length > 1,
              'justify-around': loading || tools?.length === 0,
              'overflow-rtl-dark': theme === 'dark',
              'overflow-rtl-light': theme !== 'dark',
            }
          )}
        >
          <>
            {!loading && tools?.length === 0 && (
              <div className="flex items-center justify-center w-full h-full">
                <div className="flex justify-center items-center flex-col">
                  <ArchiveBoxXMarkIcon className="w-12 h-12" />
                  <p
                    className={cn('text-base font-bold font-figtree', {
                      'text-crystal-bell': theme === 'dark',
                      'text-raisin-black': theme !== 'dark',
                    })}
                  >
                    No results found
                  </p>
                </div>
              </div>
            )}
            {tools?.map((tool, index) => (
              <TemplateCard
                id={tool._id}
                multi_default_models={tool?.multi_default_models}
                default_model={tool?.default_model}
                icon={tool?.Icon}
                key={index}
                category={tool.category}
                title={tool.title}
                description={tool?.desc}
                onClick={() => {
                  onSelectTemplate(tool._id);
                  handleClose();
                }}
                userInfo={
                  tool.creator
                    ? {
                        ...tool.creator,
                      }
                    : null
                }
                files={tool?.files ?? []}
                runs={tool?.runs}
              />
            ))}
            {loading && (
              <>
                {Array.from({ length: 20 }).map((_, index) => (
                  <LoadingTemplateCard
                    key={index}
                    className="my-3 !w-[260px] h-[160px]"
                  />
                ))}
              </>
            )}
            <div className="flex items-center justify-center w-full">
              <InView onChange={onChangeLimit}>
                <div className="flex items-center justify-center">.</div>
              </InView>
            </div>
          </>
        </div>
      </DialogContent>
      {/* BROWSE */}
      <DialogActions
        className={cn('flex flex-col w-full', {
          'bg-seasalt': theme !== 'dark',
          'bg-lead': theme === 'dark',
        })}
      >
        <div
          className={cn(
            'w-full flex justify-between cursor-pointer border-t py-6 px-1 md:px-9',
            {
              'border-lead': theme !== 'dark',
              'border-white': theme === 'dark',
            }
          )}
          onClick={onToggleBrowse}
        >
          <div className="flex items-center">
            <div
              className={cn(
                'flex items-center gap-2 md:text-base text-sm font-bold',
                {
                  'text-crystal-bell': theme === 'dark',
                  'text-raisin-black': theme !== 'dark',
                }
              )}
            >
              <MagnifyingGlassCircleIcon className="w-6 h-6" />
              <p className="font-figtree">Browse</p>
            </div>
            {openBrowse && (
              <div className={cn('ml-2 flex gap-2 items-center')}>
                <MiniTab title="Folders" active />
              </div>
            )}
          </div>
          <div className="flex items-center gap-2">
            {openBrowse && (
              <div className="flex items-center gap-2">
                <label
                  className={cn('font-figtree font-bold text-sm capitalize', {
                    'text-crystal-bell': theme === 'dark',
                    'text-raisin-black': theme !== 'dark',
                  })}
                >
                  {sort === 'asc' ? 'A-Z' : 'Z-A'}
                </label>
                <div className="flex items-center">
                  <IconButton onClick={(e) => onToggleSort(e, 'asc')}>
                    <BarsArrowDownIcon className="w-4 h-4" />
                  </IconButton>
                  <IconButton onClick={(e) => onToggleSort(e, 'desc')}>
                    <BarsArrowUpIcon className="w-4 h-4" />
                  </IconButton>
                </div>
              </div>
            )}
            <div
              className={cn('flex', {
                'text-crystal-bell': theme === 'dark',
                'text-raisin-black': theme !== 'dark',
              })}
            >
              {!openBrowse ? <ExpandLess /> : <ExpandMore />}
            </div>
          </div>
        </div>
        <Collapse in={openBrowse} timeout="auto" unmountOnExit>
          <div
            className={cn(
              'w-full px-1 md:px-9 mb-6 flex flex-wrap items-center justify-evenly md:justify-normal gap-2 max-h-[200px] overflow-auto overflow-rtl',
              {
                'overflow-rtl-dark': theme === 'dark',
                'overflow-rtl-light': theme !== 'dark',
              }
            )}
          >
            {memoTemplateFolders?.map((folder) => {
              return (
                <TabIcon
                  title={folder.name}
                  key={folder._id}
                  icon={CATEGORIES_ICONS[folder.name.toLowerCase()]}
                  active={watch('folders')?.includes(folder._id)}
                  onClick={() => {
                    const folders = watch('folders') ?? [];
                    if (folders.includes(folder._id)) {
                      setValue(
                        'folders',
                        folders.filter((i) => i !== folder._id)
                      );
                    } else {
                      setValue('folders', [...folders, folder._id]);
                    }
                  }}
                />
              );
            })}
          </div>
        </Collapse>
      </DialogActions>
    </CommonModal>
  );
};

export default inject('store')(observer(PromptLibrary));
