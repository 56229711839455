/* eslint-disable eqeqeq */
/* eslint-disable no-unreachable */
/* eslint-disable no-useless-escape */
/* eslint-disable no-console */
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
// SEO
import { Helmet } from 'react-helmet';
// Mobx
import { inject, observer } from 'mobx-react';
// Icons
import {
  ArrowLeftIcon,
  ArrowLongRightIcon,
  EyeIcon,
  EyeSlashIcon,
  MicrophoneIcon,
  PaperClipIcon,
  StopIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import {
  CurrencyDollarIcon,
  InformationCircleIcon,
  XCircleIcon,
} from '@heroicons/react/24/solid';
import ClearIcon from '../../Theme/icons/titleHeaderIcons/clearIcon';
import DeleteIcon from '../../Theme/icons/titleHeaderIcons/deleteIcon';
import EditIcon from '../../Theme/icons/titleHeaderIcons/editIcon';
// Libs
import moment from 'moment';
import { useAudioRecorder } from 'react-audio-voice-recorder';
// Context
import { useChat } from '../../Context/Chat/Chat.context';
// Hooks
import { IconButton, Tooltip, useMediaQuery, useTheme } from '@mui/material';
import useCopyWithoutFormat from 'Hooks/useCopyWithoutFormat';
import useDebounce from 'Hooks/useDebounce';
import { toastReward } from 'utils/toastCall';
import { parseQueryString } from 'utils/urlParse';
// React router
import ModelDialog from 'Components/ModelDialog';
import { useLocation, useNavigate } from 'react-router-dom';
// MUI
// Toast
import toast from 'react-hot-toast';
// cosntants
import {
  highlightWordsTextAreaV2,
  highlightWordsTextAreaV2Dark,
} from 'Constants/chat';
// Axios
import LinearProgress from '@mui/material/LinearProgress';
import ActionsChat from 'Components/Chat/ActionsChat/ActionsChat';
import useShareLinkTool from 'Components/Chat/PromptModal/store';
import useTextAreaFocus from 'Components/Chat/TextAreaHightlight/state';
import useChatStore from 'Components/Chat/store';
// Chat events
import {
  CAPABILITIES_CHANGE,
  EVENT_NEW_CHAT,
  EVENT_SELECT_CHAT,
  LOADING_FAV_TOOLS,
  REMOVE_MODEL,
  SCRIPT_FOR_TTS,
} from 'Components/Chat/store';
// Render Types
import { COLUMNS, GRID, ROWS, TABS } from 'Components/Chat/store';
// Framer motion
import ChatMessage from 'Components/Common/Markdown/ChatMessage';
import FileContext from 'Components/FileContext';
import ImageDialog from 'Components/ImageDialog';
import Loader from 'Components/Loader';
import TextSpeechDialog from 'Components/TextSpeechDialog';
import axios from 'axios';
import config from 'config';
import { AnimatePresence, motion } from 'framer-motion';
import MenuActionsTextField from '../../Components/Chat/MenuActionsTextField/MenuActionsTextField';
import TextAreaHightlight from '../../Components/Chat/TextAreaHightlight/TextAreaHightlight';
import ClearChat from './Components/ClearChat/ClearChat';
import DeleteChat from './Components/DeleteChat';
import EditTitle from './Components/EditTitle';
import ShareChat from './Components/ShareChat/ShareChat';
import { Shortcut } from './styled';
import { formatTime, formatedDate } from './utils/format';
//New file icons
import CoinsAlertModal from 'Components/Chat/CoinsAlertModal';
import useAlertCoinsStore from 'Components/Chat/CoinsAlertModal/store';
import useMenuActionsTextFieldLogic from 'Components/Chat/MenuActionsTextField/hooks/useMenuActionsTextFieldLogic';
import useMenuActionStore from 'Components/Chat/MenuActionsTextField/store';
import PromptLibrary from 'Components/Chat/PromptLibrary';
import usePromptLibraryStore from 'Components/Chat/PromptLibrary/store';
import TemplateModal from 'Components/Chat/TemplateModal';
import DeleteTemplateModal from 'Components/Chat/TemplateModal/components/DeleteTemplateModal';
import useGetTemplateData from 'Components/Chat/TemplateModal/hooks/useGetTemplateData';
import useTemplateModalStore from 'Components/Chat/TemplateModal/store';
import useTreeDNDChatStore from 'Components/Chat/TreeDNDChat/Store';
import { buildDataTreeDNDChat } from 'Components/Chat/TreeDNDChat/Utils/functions';
import FavoriteMessages from 'Components/Common/Markdown/FavoriteMessages';
import useChatMessageStore from 'Components/Common/Markdown/store';
import ModelWarningDialog from 'Components/ModelWarningDialog';
import ScrollableToolsSection from 'Components/ScrollableToolsSection';
import WordcapLimitsDialog from 'Components/WordcapLimitsDialog';
import useTemplateShare from 'Features/Core/logic/useTemplateShare';
import useDisableIOSZoom from 'Hooks/useDisableIOSZoom';
import {
  ColumnsIcon,
  GridIcon,
  RowsIcon,
  TabsIcon,
} from 'Theme/icons/sideBySideIcons';
import useThemeStore from 'Theme/store';
import { throttle } from 'lodash';
import ToggleSmartMode from '../../Components/Common/ToggleSmartMode';
import useSmartModeStore from '../../Features/SmartMode/store';
import AttachedFiles from './Components/AttachedFiles/AttachedFiles';
import useCatalog from './logic/useCatalog';

const svgIconBase64 = `data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjciIGhlaWdodD0iMzQiIHZpZXdCb3g9IjAgMCAyNyAzNCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMjYuNSA4LjVIMTkuMjc3NkwyMy42MTA5IDBIMTIuMDU1OEwwLjUgMjIuNjY2NEg5LjE2NjY3VjM0TDI2LjUgOC41WiIgZmlsbD0iaW5oZXJpdCIvPjwvc3ZnPg==`;

const dummyId = () => {
  const hexaChars = '0123456789abcdef';
  let id = '';

  for (let i = 0; i < 24; i++) {
    const randomIndex = Math.floor(Math.random() * hexaChars.length);
    id += hexaChars.charAt(randomIndex);
  }

  return id;
};

let onNewChat;

const Chat = inject('store')(
  observer(({ store }) => {
    useCopyWithoutFormat();
    useDisableIOSZoom();

    const location = useLocation();
    const navigate = useNavigate();

    // Theme
    const theme = useTheme();
    // Media query for mobile
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const [inputError] = useState(null);
    const [inputValue, setInputValue] = useState('');
    const [loading, setLoading] = useState(false);
    const inputContainerRef = useRef(null);

    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [showEditDialog, setShowEditDialog] = useState(false);
    const [toDelete, setToDelete] = useState(null);
    const [avatar, setAvatar] = useState(null);
    const [deleted, setDeleted] = useState(false);
    const [cleared, setCleared] = useState(false);

    const [showFileDialog, setShowFileDialog] = useState(false);
    const [showTextSpeechDialog, setShowTextSpeechDialog] = useState(false);
    const [showClearDialog, setShowClearDialog] = useState(false);
    const [showWarningDialog, setShowWarningDialog] = useState(false);
    const [showLimitDialog, setShowLimitDialog] = useState(false);

    const [loadingBar, setLoadingBar] = useState(false);
    const [axiosSource, setAxiosSource] = useState(false);
    const [cancel, setCancel] = useState(false);
    const [currentTemplateModels, setCurrentTemplateModels] = useState([]);

    const userMessage = useRef(null);
    const dummy = useRef(null);
    const chatId = useRef(null);

    const [scriptValue, setScriptValue] = useState(null);

    const [welcomeMessage, setWelcomeMessage] = useState(['', '']);
    const stopped = useRef(false);

    const setSelectedModels = (models) => {
      oldSelectedModels.current = sortByAttribute(models, 'abbreviation');
      updateSelectedModels(() => sortByAttribute(models, 'abbreviation'));
      localStorage.setItem('currentModels', JSON.stringify(models));
      // For editing a message
      setEditMessChange();
    };

    const defaultOptions = [
      { name: 'New Chat', isHidden: false },
      { name: 'Select Model', isHidden: false },
      { name: 'Text To Speech', isHidden: false },
      { name: 'Open prompt templates menu', isHidden: false },
      { name: 'Clear Chat', isHidden: false },
    ];

    const [filteredOptions, setFilteredOptions] = useState(defaultOptions);

    /**
     * --------------------------------------
     * LOGIC THAT OPEN THE TEMPLATE MODAL AND
     * GET THE DATA FROM THE TEMPLATE
     */
    useGetTemplateData(store);
    // --------------------------------------

    useEffect(() => {
      if (!inputValue) {
        setFilteredOptions(defaultOptions);
      } else {
        const sanitizedInput = inputValue
          .toLowerCase()
          .replace(/[^a-z0-9 ]/g, '');
        const updatedOptions = defaultOptions.map((option) => ({
          ...option,
          isHidden: !option.name.toLowerCase().includes(sanitizedInput),
        }));
        setFilteredOptions(updatedOptions);
      }
    }, [inputValue]);

    const { setEditMessChange } = useChatMessageStore();

    const [capabilitiesList, setCapabilitiesList] = useState([]);

    const sortByAttribute = (arr, attribute) => {
      return arr?.sort((a, b) => {
        if (a[attribute] < b[attribute]) {
          return -1;
        }
        if (a[attribute] > b[attribute]) {
          return 1;
        }
        return 0;
      });
    };

    // Url params control
    const setChatUrlValue = (value) => {
      const searchParams = new URLSearchParams(location.search);
      searchParams.delete('selectedTool');
      searchParams.set('chat', value);
      const newSearch = `?${searchParams.toString()}`;
      navigate(`${location.pathname}${newSearch}`, { shallow: true });
    };
    const removeChatUrlValue = () => {
      const searchParams = new URLSearchParams(location.search);
      searchParams.delete('chat');
      const newSearch = `?${searchParams.toString()}`;
      navigate(`${location.pathname}${newSearch}`, { shallow: true });
    };

    // activate delete dialog
    const {
      openDeleteChatModal,
      setOpenDeleteChatModal,
      openClearChatModal,
      setOpenClearChatModal,
      toDelete: toDeleteDND,
      setToDelete: setToDeleteDND,
    } = useTreeDNDChatStore();

    useEffect(() => {
      (async () => {
        await getCapabilities();
      })();
    }, []);

    const getCapabilities = async () => {
      try {
        const response = await store.api.get(`/capability`);
        setCapabilitiesList(response.data.capabilities);
      } catch (error) {
        console.error(error);
      }
    };

    useEffect(() => {
      if (openDeleteChatModal) {
        setShowDeleteDialog(true);
        setOpenDeleteChatModal(false);
      }
    }, [openDeleteChatModal]);

    useEffect(() => {
      if (openClearChatModal) {
        setShowClearDialog(true);
        setOpenClearChatModal(false);
      }
    }, [openClearChatModal]);

    useEffect(() => {
      if (toDeleteDND) {
        setToDelete(toDeleteDND);
      }
    }, [toDeleteDND]);

    // Context
    const {
      lastestPrompt,
      lastestTool,
      cleanLastestFields,
      setValuesAfterInsert,
      tools,
      promptListRef,
      textareaRef,
      toggleShowPromptSide,
      getToolsAndFilter,
      activePromptIndex,
      setActivePromptIndex,
      selectedTool,
      setSelectedTool,
      setSelectedPrompt,
      setOptionsPrompt,
      getPromptsByTool,
      toggleShowPromptModal,
      setShowMobileChats,
      setShowMobilePrompts,
      setPromptValue,
      handleShowMobilePrompts,
      getToolsAndFilterSidebar,
      setShowConversationsSide,
    } = useChat();

    const { templatesChatList, openMenuAction, setOpenMenuAction } =
      useMenuActionStore();

    /**
     * This is the logic to manage open the template modal
     */
    const {
      openModal: openModalTemplate,
      isTemplatePrompt,
      setIsTemplatePrompt,
      setTemplateData,
    } = useTemplateModalStore();

    const chatList = useChatStore((state) => state.chatList);
    const selectedChat = useChatStore((state) => state.selectedChat);
    const setChatsList = useChatStore((state) => state.setChatsList);
    const setSelectedChat = useChatStore((state) => state.setSelectedChat);
    const cleanSelectedChat = useChatStore((state) => state.cleanSelectedChat);
    const selectChat = useChatStore((state) => state.selectChat);
    const chatWords = useChatStore((state) => state.chatWords);
    const setChatWords = useChatStore((state) => state.setChatWords);
    const maxMessageModels = useChatStore((state) => state.maxMessageModels);
    const setMaxMessageModels = useChatStore(
      (state) => state.setMaxMessageModels
    );
    const refFile = useChatStore((state) => state.refFile);
    const setRefFile = useChatStore((state) => state.setRefFile);
    const updateRefFile = useChatStore((state) => state.updateRefFile);
    const powerPanelOpen = useChatStore((state) => state.powerPanelOpen);
    const selectedModels = useChatStore((state) => state.selectedModels);
    const updateSelectedModels = useChatStore(
      (state) => state.updateSelectedModels
    );
    const showModelsDialog = useChatStore((state) => state.showModelsDialog);
    const setShowModelsDialog = useChatStore(
      (state) => state.setShowModelsDialog
    );
    const runEvent = useChatStore((state) => state.runEvent);
    const addEvent = useChatStore((state) => state.addEvent);
    const currentEvent = useChatStore((state) => state.currentEvent);
    const renderType = useChatStore((state) => state.renderType);
    const setRenderType = useChatStore((state) => state.setRenderType);
    const headerVisible = useChatStore((state) => state.headerVisible);
    const hideOnHover = useChatStore((state) => state.hideOnHover);
    const setHideOnHover = useChatStore((state) => state.setHideOnHover);
    const addResponseModels = useChatStore((state) => state.addResponseModels);
    const setAddResponseModels = useChatStore(
      (state) => state.setAddResponseModels
    );

    // Folder manage STATE
    const { treeData, setTreeData, setPage, setTotalPage } =
      useTreeDNDChatStore();

    // Images variables
    const [imagesPreview, setImagesPreview] = useState([]);
    const [hoveredIndex, setHoveredIndex] = useState(null);
    const [params, setParams] = useState({});
    const [behaviours, setBehaviours] = useState([]);
    const [attrs, setAttrs] = useState({});
    const [currentPrompt, setCurrentPrompt] = useState(null);
    const [showImageDialog, setShowImageDialog] = useState(false);
    const [dialogContent, setDialogContent] = useState('');

    //id of chat
    const [xchatId, setXchatId] = useState(null);

    const dummyMessage = useRef(null);
    const lastModel = useRef(null);
    const toolData = useRef(null);
    const streamCode = useRef(null);
    const loadingResponse = useRef(false);

    const { smartMode } = useSmartModeStore();

    //--------------------AUTO SCROLL CHAT-----------------------------------------
    const chatContainer = useRef(null);
    const [autoScroll, setAutoScroll] = useState(true);
    const messageRefs = useRef({ byId: {}, byDate: {} }); // Refs for messages

    const handleScroll = () => {
      if (!chatContainer.current) return;
      // eslint-disable-next-line no-unsafe-optional-chaining
      const { scrollTop, scrollHeight, clientHeight } = chatContainer?.current;
      if (scrollHeight - scrollTop === clientHeight) {
        setAutoScroll(true);
      } else {
        setAutoScroll(false);
      }
    };

    useEffect(() => {
      if (currentEvent) {
        eventActions(currentEvent);
      }
    }, [currentEvent]);

    useEffect(() => {
      getToolsAndFilterSidebar('');
    }, []);

    const eventActions = (event) => {
      switch (event.name) {
        case EVENT_NEW_CHAT:
          runEvent(onNewChat);
          break;
        case EVENT_SELECT_CHAT:
          runEvent(onSelectChat);
          break;
        case SCRIPT_FOR_TTS:
          runEvent(handleScriptForTTS);
          break;
        case REMOVE_MODEL:
          runEvent(removeModel);
          break;
        default:
          break;
      }
    };

    useEffect(() => {
      const container = chatContainer.current;

      if (autoScroll && container) {
        container.scrollTop = container.scrollHeight + 100;
      }

      const handleUserScroll = () => {
        if (!container) return;
        const { scrollTop, scrollHeight, clientHeight } = container;
        const atBottom = scrollHeight - scrollTop === clientHeight;
        if (!atBottom) {
          setAutoScroll(false);
        }
      };

      if (container) {
        container.addEventListener('scroll', handleUserScroll);
      }

      return () => {
        if (container) {
          container.removeEventListener('scroll', handleUserScroll);
        }
      };
    }, [autoScroll]);
    //------------------------------AUTO SCROLL CHAT----------------------------------------

    // Capabilities variables
    const [capabilities, setCapabilities] = useState(
      JSON.parse(localStorage.getItem('capabilities')) || []
    );

    useEffect(() => {
      (async () => {
        try {
          let defaultModel = localStorage.getItem('multimodelDefault');

          if (!defaultModel) {
            localStorage.removeItem('currentModels');
          }
          let currentModels = localStorage.getItem('currentModels');
          const responseMultiModal = await store.api.get(
            `/model/multimodelDefault`
          );
          //TODO cuando lo llama al principio
          if (currentModels === responseMultiModal.data.models) {
            setSelectedModels(JSON.parse(currentModels));
          } else {
            if (responseMultiModal.data.models.length === 0) {
              const response = await store.api.get(`/model/default`);
              setSelectedModels([response.data.model]);
              localStorage.setItem(
                'currentModels',
                JSON.stringify([response.data.model])
              );
              localStorage.setItem(
                'multimodelDefault',
                JSON.stringify([response.data.model])
              );
            } else {
              setSelectedModels(responseMultiModal.data.models);

              localStorage.setItem(
                'multimodelDefault',
                JSON.stringify(responseMultiModal.data.models)
              );

              localStorage.setItem(
                'currentModels',
                JSON.stringify(responseMultiModal.data.models)
              );
            }
          }
        } catch (error) {
          console.error(error);
        }
        setWelcomeMessage(getHello());
      })();
    }, []);

    useEffect(() => {
      setAvatar(store.profile.avatar.svg);
      setShowConversationsSide(true);
      if (!isMobile) {
        toggleShowPromptSide();
      }
    }, []);

    const eventHandler = useCallback((event) => {
      if (dummy.current && !stopped.current && loadingResponse.current) {
        if (dummyMessage.current && dummyMessage.current.length > 1) {
          const index = dummyMessage.current.findIndex(
            (m) => m.modelRef == event.modelRef
          );
          dummyMessage.current[index] = dummyMessage.current[index]._id
            ? { ...dummyMessage.current[index], ...event }
            : { ...event, _id: dummyId() };
        } else {
          dummyMessage.current = dummyMessage.current._id
            ? { ...dummyMessage.current, ...event }
            : { ...event, _id: dummyId() };
        }

        setSelectedChat({
          ...dummy.current,
          messages: [dummyMessage.current, ...dummy.current.messages],
        });

        if (
          !chatId ||
          (event.chatId !== null && event.chatId !== chatId.current)
        ) {
          chatId.current = event.chatId;
        }
      }
    }, []);

    useEffect(() => {
      const throttledHandler = throttle(eventHandler, 300);
      store.listenEvent('writing', throttledHandler);
      return () => {
        store.removeListener('writing', throttledHandler);
        throttledHandler.cancel();
      };
    }, [eventHandler]);

    const oldSelectedModels = useRef(null);
    useEffect(() => {
      updateDummyMessage(oldSelectedModels.current ?? []);
      streamCode.current = btoa(JSON.stringify(oldSelectedModels.current));
    }, [selectedModels]);

    const updateDummyMessage = (models) => {
      if (models.length == 0) {
        dummyMessage.current = null;
      } else if (models.length > 1) {
        dummyMessage.current = models.map((model) => {
          return {
            model: model.abbreviation,
            modelRef: model._id,
            data: '',
            finished: false,
          };
        });
      } else {
        dummyMessage.current = {
          model: models[0].abbreviation,
          modelRef: models[0]._id,
          data: '',
          finished: false,
        };
      }
    };

    const getChats = async () => {
      setLoadingBar(true);
      setLoading(true);
      try {
        const res = await store.api.get(`/chat/v1/folders?page_number=${1}`);
        const data = await res.data;
        const chats = data.chats;

        setPage(data.currentPage);
        setTotalPage(data.totalPages);

        const newTree = buildDataTreeDNDChat(chats);
        setTreeData(newTree);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      } finally {
        setLoading(false);
        setLoadingBar(false);
      }
    };

    /**
     * Get chat by hash from url
     * @param {string} hash
     * @returns
     */
    const isFirstLoad = useRef(true);

    useEffect(() => {
      if (isFirstLoad.current) {
        const hash = parseQueryString(location.search)?.chat;
        if (hash !== selectedChat?.chat?._id) {
          if (hash && chatList?.length > 0) {
            getChat(hash);
            selectChat(hash);
            setShowMobileChats(false);
          } else if (hash) {
            getChat(hash);
          }
        }

        isFirstLoad.current = false;
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getChat = async (hash, messageIdentifier = null, isUser = null) => {
      setLoadingBar(true);
      setChatWords(0);

      try {
        let hashShared = null;
        if (sessionStorage.getItem('redirectUrl')) {
          const res = await store.api.post(`/chat/clone/${hash}`);
          const data = await res.data;
          hashShared = data?.chat?._id;
          sessionStorage.removeItem('redirectUrl');
        }
        const localHash = hashShared ?? hash;
        // add query params url
        setChatUrlValue(localHash);
        /*
        This is important when change chat
        remove the animation to fix the bug
        */
        setLoading(false);
        //
        const res = await store.api.get('/chat/' + localHash);
        setChat(res.data.chat);
        setXchatId(localHash);
        if (res.data.chat.files) {
          setRefFile(res.data.chat.files);
        }

        if (res.data.chat.messages && res.data.chat.messages.length) {
          countTotalWords(res.data.chat.messages);
          try {
            const lastMessage = res.data.chat.messages[0];
            lastModel.current = lastMessage.find((r) => r.selected).modelRef;
            const modelRef = Array.isArray(lastMessage)
              ? lastMessage.map((m) => m.modelRef)
              : lastMessage.modelRef;
            const response = await store.api.get('/model/byId', {
              params: {
                default_model_template: modelRef,
              },
            });
            if (response.data.model.length > 0) {
              if (store.profile.plan == 'free') {
                try {
                  const response2 = await store.api.get(`/model`);
                  let availableModels = response2.data.models;

                  const res = await store.api.get('/user/constant', {
                    params: {
                      type: 'princing_plans',
                    },
                  });
                  let freePlanModels = []; // Array to store models allowed for free users

                  freePlanModels = res.data.rules[0].models;

                  const modelsInFreePlan = []; // Array to store models available for free users
                  const otherModels = []; // Array to store models not available for free users

                  availableModels.forEach((model) => {
                    if (freePlanModels.includes(model.model)) {
                      modelsInFreePlan.push(model);
                    } else {
                      otherModels.push(model);
                    }
                  });

                  let filterModels = modelsInFreePlan.filter((model) =>
                    response.data.model.map((m) => m._id).includes(model._id)
                  );

                  if (filterModels.length >= 1) {
                    setSelectedModels(response.data.model);
                  } else {
                    setSelectedModels([availableModels[0]]);
                  }
                } catch (error) {
                  console.error(error);
                }
              } else {
                setSelectedModels(response.data.model);
              }
              localStorage.setItem(
                'currentModels',
                JSON.stringify(response.data.model)
              );
            } else {
              let defaultModel = localStorage.getItem('defaultModel');
              if (defaultModel) {
                setSelectedModels([JSON.parse(defaultModel)]);
              }
            }
          } catch (error) {
            console.error(error);
          }
        }

        // If messageIdentifier is passed, scroll to that message
        if (messageIdentifier !== null && isUser !== null) {
          scrollToMessage(messageIdentifier, isUser);
        } else {
          goToDown();
        }
      } catch (error) {
        removeChatUrlValue();
        console.error(error);
      } finally {
        setLoadingBar(false);
      }
    };

    const countTotalWords = (messages) => {
      let chatWordsCount = 0;
      let maxModels = maxMessageModels;

      messages.forEach((item) => {
        // count models
        let models = Array.isArray(item) ? item.length : 1;
        if (models > maxModels) {
          setMaxMessageModels(models);
        }

        // Get selected message
        let temp = Array.isArray(item)
          ? item.filter((m) => m.selected == true)
          : [item];
        let msg = temp.length > 0 ? temp[0] : null;

        if (msg && msg.data && msg.data.content) {
          if (typeof msg.data.content == 'string') {
            const wordsContent = store.countWords(msg.data.content);
            chatWordsCount += wordsContent;
          } else {
            for (let content of msg.data.content) {
              if (content.type == 'text') {
                const wordsContent = store.countWords(content.text);
                chatWordsCount += wordsContent;
              } else if (content.type == 'image_url') {
                const visionModel = selectedModels.some(
                  (model) => model.type == 'vision'
                );
                chatWordsCount += visionModel ? content.image_url.words : 0;
              }
            }
          }
        }
      });
      setChatWords(chatWordsCount);
    };
    const goToDown = () => {
      if (chatContainer && chatContainer.current) {
        const containerElement = chatContainer.current;
        const scrollPosition =
          containerElement.scrollHeight - containerElement.offsetHeight;

        const animateScroll = () =>
          containerElement.scrollTo({
            top: scrollPosition,
            behavior: 'smooth',
          });

        requestAnimationFrame(animateScroll);
      }
    };

    const scrollToMessage = (identifier, isUserMessage) => {
      if (isUserMessage === null) {
        return;
      }

      const messageElement = isUserMessage
        ? messageRefs.current.byDate[identifier]
        : messageRefs.current.byId[identifier];

      if (messageElement && chatContainer.current) {
        const containerElement = chatContainer.current;
        const scrollPosition =
          messageElement.offsetTop - containerElement.offsetTop;

        const animateScroll = () =>
          containerElement.scrollTo({
            top: scrollPosition,
            behavior: 'smooth',
          });

        requestAnimationFrame(animateScroll);
      }
    };

    // cancel chat
    const cancelChat = () => {
      if (!stopped.current && userMessage && userMessage.current) {
        toast('Switching windows interrupts response', {
          icon: '📢',
        });
      }
      if (axiosSource) {
        setCancel(true);
        userMessage.current = null;
        axiosSource.cancel();
      }
    };
    // redirect to path with query
    const redirectToPathWithQuery = (query) => {
      navigate(
        {
          pathname: location.pathname,
          search: new URLSearchParams({
            ...parseQueryString(location.search, query),
          }).toString(),
        },
        { shallow: true }
      );
    };
    // go back chat
    const goBackChat = () => {
      redirectToPathWithQuery(location.pathname, parseQueryString('chat'));
      dummy.current = { _id: null, messages: [] };
      cleanSelectedChat();
      setRefFile(null);
    };
    // set chat
    const setChat = (chat) => {
      setSelectedChat(chat);
      dummy.current = chat;
      // moveDown();
    };

    const containsVisionModel = () => {
      return (
        selectedModels.length &&
        selectedModels.some((model) => model.type === 'vision')
      );
    };

    const {
      setOpenModal: setCoinsAlertOpen,
      dissmissed: dissmissedAlertCoins,
    } = useAlertCoinsStore();

    const handleConfirmAlertCoins = () => {
      onSend(null, undefined, undefined, undefined, true);
      setCoinsAlertOpen(false);
    };
    const handleCloseAlertCoins = () => {};

    const continueResponse = () => {
      onSend(null, 'continue', undefined, undefined, true);
    };

    const [triggerAnimation, setTriggerAnimation] = useState(false);
    let [optimizing, setOptimizing] = useState(false);
    const [sendTriggered, setSendTriggered] = useState(false);

    useEffect(() => {
      if (sendTriggered) {
        setLoading(true);
        setTriggerAnimation(true);

        if (smartMode === 'on') {
          setOptimizing(true);
        }
        setSendTriggered(false);
      }
    }, [sendTriggered, smartMode]);

    const optimizePrompt = async (originalPrompt) => {
      if (!originalPrompt || originalPrompt.trim() === '') {
        console.warn('The originalPrompt is not valid for optimization');
        return {
          optimizedPrompt: originalPrompt,
          LLMRole: '',
          basePrompt: '',
          systemInstructions: '',
        };
      }

      try {
        const chatId = selectedChat?.chat || null;
        const responseOptimization = await store.api.post(
          `/ai/chat/optimize-prompt`,
          {
            originalPrompt: originalPrompt,
            coins: 10,
            chatId: chatId,
          }
        );

        if (!responseOptimization.data.success) {
          if (responseOptimization.data.error === 'missing_coins') {
            toastReward({
              title: 'Missing coins',
              message: 'Recharge your account on the profile page',
              linkText: "It's quick and easy. Click here!",
              linkUrl: '/my-profile/pricing',
              image: '/gifs/coin.gif',
              time: 10000,
            });
            return {
              optimizedPrompt: originalPrompt,
              LLMRole: '',
              basePrompt: '',
              systemInstructions: '',
            };
          } else {
            toast.error(responseOptimization.data.error);
            return {
              optimizedPrompt: originalPrompt,
              LLMRole: '',
              basePrompt: '',
              systemInstructions: '',
            };
          }
        }
        const basePrompt = responseOptimization.data.basePrompt;
        const systemInstructions = responseOptimization.data.systemInstructions;
        const needsOptimization = responseOptimization.data.needsOptimization;
        if (!needsOptimization) {
          const truncateText = (text, maxLength) => {
            if (text.length <= maxLength) return text;
            const words = text.split(' ');
            let truncated = '';
            for (let word of words) {
              if ((truncated + word).length > maxLength) break;
              truncated += (truncated ? ' ' : '') + word;
            }
            return truncated.trim() + '...';
          };
          const displayPrompt = truncateText(originalPrompt, 30);

          const customToastOptions = {
            style: {
              background: '#D4D5E6',
              color: '#000',
              boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
              borderRadius: '10px',
              padding: '16px',
              display: 'flex',
              alignItems: 'center',
              width: isMobile ? '100%' : 'auto',
              maxWidth: isMobile ? '100%' : '30%',
              minWidth: isMobile ? '100%' : '20%',
            },
            icon: (
              <img
                src={svgIconBase64}
                alt="Lightning Icon"
                width={isMobile ? '20' : '24'}
                height={isMobile ? '20' : '24'}
                style={{
                  marginRight: '8px',
                  filter:
                    'invert(34%) sepia(96%) saturate(5500%) hue-rotate(245deg) brightness(60%) contrast(95%)',
                }}
              />
            ),
            duration: 2000,
            position: isMobile ? 'top-center' : 'top-right',
          };

          toast(
            <div>
              <div
                style={{
                  fontWeight: 700,
                  fontSize: isMobile ? '16px' : '16px',
                  marginBottom: '4px',
                  color: '#5256A6',
                  fontFamily: 'Figtree, sans-serif',
                }}
              >
                Smart Mode – Prompt Optimization
              </div>
              <div
                style={{
                  fontSize: isMobile ? '14px' : '14px',
                  color: '#6B6B6B',
                }}
              >
                Prompt "<strong>{displayPrompt}</strong>" was not optimizable
              </div>
            </div>,
            customToastOptions
          );
          return {
            optimizedPrompt: originalPrompt,
            LLMRole: '',
            needsOptimization,
            basePrompt: basePrompt,
            systemInstructions: systemInstructions,
          };
        }

        if (responseOptimization?.data?.optimizedPrompt) {
          let finalPrompt = `${responseOptimization.data.optimizedPrompt}`;
          let LLMRole = responseOptimization.data.role;

          if (
            responseOptimization.data.importantContent &&
            responseOptimization.data.importantContent !== 'N/A'
          ) {
            const originalPromptNoSpaces = originalPrompt.replace(/\s+/g, '');
            const importantContentNoSpaces =
              responseOptimization.data.importantContent.replace(/\s+/g, '');

            if (originalPromptNoSpaces !== importantContentNoSpaces) {
              finalPrompt += `\n\n${responseOptimization.data.importantContent}`;
            }
          }

          return {
            optimizedPrompt: finalPrompt,
            LLMRole: LLMRole,
            needsOptimization,
            basePrompt: basePrompt,
            systemInstructions: systemInstructions,
          };
        } else {
          return {
            optimizedPrompt: originalPrompt,
            LLMRole: '',
            needsOptimization,
            basePrompt: basePrompt,
            systemInstructions: systemInstructions,
          };
        }
      } catch (error) {
        console.error(error);
        return {
          optimizedPrompt: originalPrompt,
          LLMRole: '',
          basePrompt: '',
          systemInstructions: '',
        };
      }
    };

    // on send message
    const onSend = async (
      e,
      inputText = undefined,
      modelsId = undefined,
      files,
      proceedCoins = false
    ) => {
      let inputVal = inputValue;
      let originalPrompt = null;
      let displayedPrompt = false;

      if (!dissmissedAlertCoins) {
        // Alert user if the message is over 1000 coins
        const coins = calculateCoins(getChatWords(inputValue));

        if (coins >= 1000) {
          setCoinsAlertOpen(true);
          if (!proceedCoins) {
            return;
          }
        }
        // ------------------------------
      }
      setLoading(true);

      if (smartMode === 'on' && !isTemplatePrompt && inputVal) {
        setTriggerAnimation(true);
        originalPrompt = inputVal;
        displayedPrompt = true;
      }

      if (displayedPrompt) {
        setOptimizing(true);
        const placeholderInput = {
          user: store.profile._id,
          data: {
            role: 'user',
            content: '',
            originalPrompt: originalPrompt,
            basePrompt: '',
            systemInstructions: '',
          },
          date: moment().utc(),
          optimizing: true,
        };

        setChat({
          ...selectedChat?.chat,
          messages: [placeholderInput, ...(selectedChat?.chat?.messages ?? [])],
        });
      } else {
        setInputValue('');
      }

      let selectedModelsId = selectedModels.map((model) => model._id);

      let LLMRole = '';
      let basePrompt = '';
      let systemInstructions = '';

      if (displayedPrompt) {
        setOptimizing(true);
        try {
          const optimizationResult = await optimizePrompt(originalPrompt);
          inputVal = optimizationResult.optimizedPrompt;
          LLMRole = optimizationResult.LLMRole;
          basePrompt = optimizationResult.basePrompt;
          systemInstructions = optimizationResult.systemInstructions;
          const needsOptimization = optimizationResult.needsOptimization;
          if (!needsOptimization) {
            originalPrompt = null;
          }
        } finally {
          handleOptimizationComplete();
          setOptimizing(false);
        }
      }

      setTimeout(() => {
        setInputValue('');
        setTriggerAnimation(false);
      }, 1500);

      setOptimizing(false);
      setIsTemplatePrompt(false);

      if (inputText) {
        inputVal = inputText;
      }
      stopped.current = false;
      oldSelectedModels.current = selectedModels;
      // Validate if is loading
      if (loading)
        return toast('Wait a moment, the chat is being processed', {
          icon: '📢',
        });

      // Validate if the user message is not null or empty
      if (!inputVal || inputVal.trim() === '') {
        toast("Don't forget: Messages need text too!", {
          icon: '😱',
        });
        return;
      }

      // Send the message logic
      onCloseBoth();
      loadingResponse.current = true;

      const date = moment().utc();
      const intputSend = containsVisionModel()
        ? visionInput(inputVal)
        : inputVal;
      const oldInputValue = inputVal;
      const oldImagesPreview = imagesPreview;

      try {
        const userInput = {
          user: store.profile._id,
          data: {
            role: 'user',
            content: intputSend,
            originalPrompt: originalPrompt,
            LLMRole: LLMRole,
            basePrompt: basePrompt,
            systemInstructions: systemInstructions,
          },
          date: date,
        };

        userMessage.current = userInput;
        setChat({
          ...selectedChat?.chat,
          messages: [userInput, ...(selectedChat?.chat?.messages ?? [])],
        });

        const tool = lastestTool;
        const prompt = lastestPrompt;
        cleanLastestFields();
        setImagesPreview([]);
        setAutoScroll(true);

        if (
          tool?._id == '65805947d92e370a3d5625c6' || // Dall-E 3
          tool?._id == '66c786d7e26442bc593e575d' // Flux Pro
        ) {
          toast(
            'Note: Image generation may take a few minutes. Thank you for your patience.',
            {
              icon: '⏳',
            }
          );
        }

        const notNew = Boolean(selectedChat.chat._id);
        const source = axios.CancelToken.source();
        setAxiosSource(source);

        const timeout = setTimeout(() => {
          if (!cancel) {
            source.cancel();
            toast.error('The chat is taking too long to respond, try again.');
            setLoading(false);
            loadingResponse.current = false;
          }
        }, 4500 * 60);

        let filesTosend = null;
        if (refFile) {
          filesTosend = refFile;
        } else if (!refFile && typeof files !== 'undefined') {
          if (files.length > 0) {
            filesTosend = files;
          }
        }
        if (modelsId) {
          selectedModelsId = modelsId;
        }

        const response = await store.api.post(
          `/ai/chat`,
          {
            message: intputSend,
            originalPrompt: originalPrompt,
            LLMRole: LLMRole,
            basePrompt: basePrompt,
            systemInstructions: systemInstructions,
            idTool: tool?._id,
            hash: selectedChat?.chat?._id,
            date: date,
            files: filesTosend,
            idModel: selectedModelsId,
            coins: calculateCoins(getChatWords(inputVal)),
            params: params,
            behaviours: behaviours,
            currentPrompt: currentPrompt ? currentPrompt.name : null,
            capabilities: capabilities.map((capability) => capability._id),
            source: 'web',
            version: process.env.REACT_APP_WEB_VERSION,
            ...attrs,
          },
          { cancelToken: source.token }
        );

        clearTimeout(timeout);
        loadingResponse.current = false;
        if (!notNew || selectedChat?.chat?.isShared) await getChats();

        if (!response.data.success) {
          if (
            response.data.error === 'no_coins' ||
            response.data.error === 'missing_coins'
          ) {
            toastReward({
              title: 'Missing coins',
              message: 'Recharge your account on the profile page',
              linkText: "It's quick and easy. Click here!",
              linkUrl: '/my-profile/pricing',
              image: '/gifs/coin.gif',
              time: 10000,
            });
          }
        } else {
          if (tool && prompt) {
            await store.api.post(`/chat-analyze/create`, {
              tool: tool?._id,
              prompt: prompt?._id,
              chat: response.data.hash,
            });
          }

          setChatUrlValue(response.data.hash);
          setXchatId(response.data.hash);

          const currentChat = selectedChat;
          const obj = {
            ...currentChat?.chat,
            _id: response.data.hash,
            title: response.data.title,
            messages: [
              Array.isArray(response.data.message)
                ? [...response.data.message].map((m) => {
                    return {
                      ...m,
                      selected:
                        lastModel.current && lastModel.current == m.modelRef,
                    };
                  })
                : { ...response.data.message },
              {
                user: store.profile._id,
                data: {
                  role: 'user',
                  content: intputSend,
                  originalPrompt: originalPrompt,
                  basePrompt: basePrompt,
                  systemInstructions: systemInstructions,
                },
                date: date,
                tool: tool ? tool._id : null,
              },
              ...(currentChat?.chat?.messages ?? []),
            ],
          };
          setChat(obj);

          const newChat = {
            id: obj._id,
            parent: 0,
            droppable: false,
            text: obj.title || '',
            data: {
              _id: obj._id,
              type: 'chat',
              last_modified: new Date(),
            },
          };

          const updatedTreeData = notNew
            ? treeData.map((item) => {
                if (item.id === obj._id) {
                  return { ...item, text: obj.title };
                }
                return item;
              })
            : [...treeData, newChat];

          setTreeData(updatedTreeData);

          // Flux Pro: NSFW content detection
          if (tool?._id == '66c786d7e26442bc593e575d') {
            if (Array.isArray(response?.data?.message?.data?.content)) {
              const contentArray = response.data.message.data.content;
              const hasNsfw = contentArray.some((item) => item?.nsfw === true);

              if (hasNsfw) {
                toast(
                  'Warning: NSFW content detected. Please revise your prompt and try again.',
                  {
                    icon: '🔞',
                  }
                );
              }
            }
          }

          if (obj.messages.length) {
            countTotalWords(obj.messages);
          }

          userMessage.current = null;
          setParams({});
          setBehaviours([]);
          setAttrs({});
          setCurrentPrompt(null);
          updateDummyMessage(selectedModels);

          const lastMsg = obj.messages[0];
          if (Array.isArray(lastMsg) && lastModel.current) {
            const modelId = lastMsg.filter(
              (m) => m.modelRef == lastModel.current
            );
            if (modelId.length > 0) {
              await selectedOutput(modelId[0]._id);
              lastModel.current = modelId[0].modelRef;
            }
          }
          if (!lastModel.current) {
            lastModel.current = Array.isArray(lastMsg)
              ? lastMsg[0].modelRef
              : lastMsg.modelRef;
          }
        }
      } catch (error) {
        if (error?.response?.data?.error?.message) {
          toast(error?.response?.data?.error?.message, {
            icon: '⚠️',
          });
        }
        if (!stopped.current) {
          setChat({
            ...selectedChat?.chat,
            messages: [
              ...(selectedChat?.chat?.messages ?? []).filter(
                (m) => m.date != date
              ),
            ],
          });
          setInputValue(oldInputValue);
          setImagesPreview(oldImagesPreview);
        } else {
          setLoading(true);
          loadingResponse.current = true;
          await store.refreshTokenAndProfileCoins();
          await getChat(chatId.current);
          setLoading(false);
          loadingResponse.current = false;
        }
        checkUseTool();
        console.error(error);
      } finally {
        setLoading(false);
        loadingResponse.current = false;
        goToDown();
      }
    };

    const handleAddModels = () => {
      setAddResponseModels(true);
      setShowModelsDialog(true);
    };

    const onAddModels = async (newModels) => {
      onCloseBoth();
      const date = moment().utc();
      stopped.current = false;

      try {
        let lastMessage = selectedChat?.chat?.messages.shift();

        let arrayMessage = Array.isArray(lastMessage)
          ? lastMessage
          : [lastMessage];
        let newModelsStructure = newModels.map((model) => {
          return {
            model: model.abbreviation,
            modelRef: model._id,
            data: '',
          };
        });
        await additionalConfig(lastMessage);
        dummyMessage.current = sortByAttribute(
          [...arrayMessage, ...newModelsStructure],
          'model'
        );
        setChat({
          ...selectedChat?.chat,
          messages: [...(selectedChat?.chat?.messages ?? [])],
        });
        setLoading(true);
        loadingResponse.current = true;

        setAutoScroll(true);

        const source = axios.CancelToken.source();
        setAxiosSource(source);

        const timeout = setTimeout(() => {
          if (!cancel) {
            source.cancel();
            toast.error('The chat is taking too long to respond, try again.');
            setLoading(false);
            loadingResponse.current = false;
          }
        }, 4500 * 60);

        let currentLocalModels = JSON.parse(
          localStorage.getItem('currentModels')
        );

        const response = await store.api.post(
          `/ai/chat/attach`,
          {
            message: selectedChat?.chat?.messages[0].data.content,
            hash: selectedChat?.chat?._id,
            date: date,
            files: refFile ? refFile : null,
            idModels: newModels.map((model) => model._id),
            coins: calculateCoins(
              getChatWords(selectedChat?.chat?.messages[0].data.content)
            ),
            source: 'web',
            version: process.env.REACT_APP_WEB_VERSION,
          },
          { cancelToken: source.token }
        );

        clearTimeout(timeout);
        loadingResponse.current = false;

        if (!response.data.success) {
          if (
            response.data.error === 'no_coins' ||
            response.data.error === 'missing_coins'
          ) {
            toastReward({
              title: 'Missing coins',
              message: 'Recharge your account on the profile page',
              linkText: "It's quick and easy. Click here!",
              linkUrl: '/my-profile/pricing',
              image: '/coin.gif',
              time: 10000,
            });
          } else {
            toast.error(response.data.error);
          }
        } else {
          setChatUrlValue(response.data.hash);

          const currentChat = selectedChat;
          const obj = {
            ...currentChat?.chat,
            _id: response.data.hash,
            title: response.data.title,
            messages: [
              Array.isArray(response.data.message)
                ? sortByAttribute([...response.data.message], 'model')
                : { ...response.data.message },
              ...(currentChat?.chat?.messages ?? []),
            ],
          };
          setChat(obj);

          if (obj.messages.length) {
            countTotalWords(obj.messages);
          }

          userMessage.current = null;
          setParams({});
          setBehaviours([]);
          setAttrs({});
          setCurrentPrompt(null);
          updateDummyMessage(currentLocalModels);

          const lastMsg = obj.messages[0];
          if (Array.isArray(lastMsg) && lastModel.current) {
            const modelId = lastMsg.filter(
              (m) => m.modelRef == lastModel.current
            );
            if (modelId.length > 0) {
              await selectedOutput(modelId[0]._id);
              lastModel.current = modelId[0].modelRef;
            }
          }
        }
      } catch (error) {
        if (error?.response?.data?.error?.message) {
          toast(error?.response?.data?.error?.message, {
            icon: '⚠️',
          });
        }

        if (!stopped.current) {
          setChat({
            ...selectedChat?.chat,
            messages: [
              ...(selectedChat?.chat?.messages ?? []).filter(
                (m) => m.date != date
              ),
            ],
          });
        } else {
          setLoading(true);
          loadingResponse.current = true;
          await store.refreshTokenAndProfileCoins();
          await getChat(selectedChat.chat._id);
          setLoading(false);
          loadingResponse.current = false;
        }

        console.error(error);
      } finally {
        setLoading(false);
        loadingResponse.current = false;
        setAddResponseModels(false);
        goToDown();
      }
    };

    const additionalConfig = async (lastMessage) => {
      if (!lastMessage.additional_config) {
        return;
      }

      let { attr, params, behaviours } = lastMessage.additional_config;
      let userMessage = selectedChat?.chat?.messages[0];
      let tool = (await getPromptsByTool(userMessage.tool))[0];
      setCurrentPrompt(tool);
      setAttrs(attr);
      setParams(params);
      setBehaviours(behaviours);
    };

    // on regenerate message
    const onRegenerate = async () => {
      onCloseBoth();
      const date = moment().utc();
      stopped.current = false;

      try {
        let lastMessage = selectedChat?.chat?.messages.shift();
        await additionalConfig(lastMessage);
        setChat({
          ...selectedChat?.chat,
          messages: [...(selectedChat?.chat?.messages ?? [])],
        });
        setLoading(true);
        loadingResponse.current = true;

        setAutoScroll(true);

        const source = axios.CancelToken.source();
        setAxiosSource(source);

        const timeout = setTimeout(() => {
          if (!cancel) {
            source.cancel();
            toast.error('The chat is taking too long to respond, try again.');
            setLoading(false);
            loadingResponse.current = false;
          }
        }, 4500 * 60);

        let currentLocalModels = JSON.parse(
          localStorage.getItem('currentModels')
        );

        const response = await store.api.post(
          `/ai/chat/regenerate`,
          {
            message: selectedChat?.chat?.messages[0].data.content,
            hash: selectedChat?.chat?._id,
            date: date,
            files: refFile ? refFile : null,
            idModel: currentLocalModels.map((model) => model._id),
            coins: calculateCoins(
              getChatWords(selectedChat?.chat?.messages[0].data.content)
            ),
            source: 'web',
            version: process.env.REACT_APP_WEB_VERSION,
          },
          { cancelToken: source.token }
        );

        clearTimeout(timeout);
        loadingResponse.current = false;

        if (!response.data.success) {
          if (
            response.data.error === 'no_coins' ||
            response.data.error === 'missing_coins'
          ) {
            toastReward({
              title: 'Missing coins',
              message: 'Recharge your account on the profile page',
              linkText: "It's quick and easy. Click here!",
              linkUrl: '/my-profile/pricing',
              image: '/gifs/coin.gif',
              time: 10000,
            });
          } else {
            toast.error(response.data.error);
          }
        } else {
          setChatUrlValue(response.data.hash);

          const currentChat = selectedChat;
          const obj = {
            ...currentChat?.chat,
            _id: response.data.hash,
            title: response.data.title,
            messages: [
              Array.isArray(response.data.message)
                ? [...response.data.message]
                : { ...response.data.message },
              ...(currentChat?.chat?.messages ?? []),
            ],
          };
          setChat(obj);

          if (obj.messages.length) {
            countTotalWords(obj.messages);
          }

          userMessage.current = null;
          setParams({});
          setBehaviours([]);
          setAttrs({});
          setCurrentPrompt(null);
          updateDummyMessage(selectedModels);

          const lastMsg = obj.messages[0];
          if (Array.isArray(lastMsg) && lastModel.current) {
            const modelId = lastMsg.filter(
              (m) => m.modelRef == lastModel.current
            );
            if (modelId.length > 0) {
              await selectedOutput(modelId[0]._id);
              lastModel.current = modelId[0].modelRef;
            }
          }
        }
      } catch (error) {
        if (error?.response?.data?.error?.message) {
          toast(error?.response?.data?.error?.message, {
            icon: '⚠️',
          });
        }

        if (!stopped.current) {
          setChat({
            ...selectedChat?.chat,
            messages: [
              ...(selectedChat?.chat?.messages ?? []).filter(
                (m) => m.date != date
              ),
            ],
          });
        } else {
          setLoading(true);
          loadingResponse.current = true;
          await store.refreshTokenAndProfileCoins();
          await getChat(selectedChat.chat._id);
          setLoading(false);
          loadingResponse.current = false;
        }

        console.error(error);
      } finally {
        setLoading(false);
        loadingResponse.current = false;
        goToDown();
      }
    };

    // Only when a message is edited
    const calculateCoinsEdit = (words, imagesPreviewEdit) => {
      let totalCoins = 0;
      const currentEditModels = JSON.parse(
        localStorage.getItem('currentModels')
      );
      currentEditModels.forEach((model) => {
        const pricing = model.pricing;
        const input = words > model.words_text ? model.words_text : words;
        let imagesCost = 0;
        if (model.type == 'vision') {
          for (let img of imagesPreviewEdit) {
            imagesCost += img.image_url.words;
          }
        }
        const coins =
          (pricing.words > 0 ? (input + imagesCost) / pricing.words : 0) *
          pricing.coins;

        totalCoins += currentPrompt
          ? parseFloat(handleBehaviour(coins))
          : parseFloat(coins.toFixed(2));
      });

      return totalCoins.toFixed(2);
    };

    // Only when a message is edited
    const additionalConfigEdit = async (aiResponse, userMessage) => {
      if (!aiResponse.additional_config) {
        return;
      }

      let { attr, params, behaviours } = aiResponse.additional_config;
      let tool = (await getPromptsByTool(userMessage.tool))[0];
      setCurrentPrompt(tool);
      setAttrs(attr);
      setParams(params);
      setBehaviours(behaviours);
    };

    // Function to edit a message prompt
    const onRegenerateEdit = async (
      editText,
      datePrompt,
      currentEditChat,
      imagesEdit,
      countWordsEdit,
      previousChat,
      undoOptimization
    ) => {
      onCloseBoth();
      const date = moment().utc();
      stopped.current = false;

      let originalPrompt = null;
      let LLMRole = '';
      let displayedPrompt = false;
      let basePrompt = '';
      let systemInstructions = '';

      try {
        let selectedMessage = currentEditChat.messages.find(
          (message) => message.date === datePrompt
        );

        let selectedMessageIndex = currentEditChat.messages.findIndex(
          (message) => message.date === datePrompt
        );
        let aiIndexResponse = selectedMessageIndex - 1;

        let aiResponse = currentEditChat.messages[aiIndexResponse];

        await additionalConfigEdit(aiResponse, selectedMessage);

        setLoading(true);
        loadingResponse.current = true;

        if (
          smartMode === 'on' &&
          !isTemplatePrompt &&
          editText &&
          !undoOptimization
        ) {
          originalPrompt = editText;
          displayedPrompt = true;
        }

        if (displayedPrompt) {
          setOptimizing(true);
          try {
            const optimizationResult = await optimizePrompt(originalPrompt);
            editText = optimizationResult.optimizedPrompt;
            LLMRole = optimizationResult.LLMRole;
            basePrompt = optimizationResult.basePrompt;
            systemInstructions = optimizationResult.systemInstructions;
          } finally {
            setOptimizing(false);
          }
        }

        const source = axios.CancelToken.source();
        setAxiosSource(source);

        const timeout = setTimeout(() => {
          if (!cancel) {
            source.cancel();
            toast.error('The chat is taking too long to respond, try again.');
            setLoading(false);
            loadingResponse.current = false;
          }
        }, 4500 * 60);

        let currentLocalModels = JSON.parse(
          localStorage.getItem('currentModels')
        );

        currentEditChat.messages = currentEditChat.messages.slice(
          aiIndexResponse + 1
        );

        if (Array.isArray(currentEditChat.messages[0].data.content)) {
          currentEditChat.messages[0].data.content = [];
          currentEditChat.messages[0].data.content.push(...imagesEdit);
          currentEditChat.messages[0].data.content.push({
            type: 'text',
            text: editText,
          });
        } else {
          currentEditChat.messages[0].data.content = editText;
        }

        currentEditChat.messages[0].date = new Date(date);
        currentEditChat.messages[0].data.originalPrompt = originalPrompt;
        currentEditChat.messages[0].data.LLMRole = LLMRole;
        currentEditChat.messages[0].data.basePrompt = basePrompt;
        currentEditChat.messages[0].data.systemInstructions =
          systemInstructions;

        currentEditChat.messages[0].data.originalPrompt = originalPrompt;
        currentEditChat.messages[0].data.LLMRole = LLMRole;

        setChat({ ...currentEditChat });

        const response = await store.api.post(
          `ai/chat/editmessage`,
          {
            coins: calculateCoinsEdit(countWordsEdit, imagesEdit),
            hash: currentEditChat._id,
            files: refFile ? refFile : null,
            idModel: currentLocalModels.map((model) => model._id),
            messageEdited: editText,
            originalPrompt: originalPrompt,
            LLMRole: LLMRole,
            basePrompt: basePrompt,
            systemInstructions: systemInstructions,
            date: date,
            imagesEdit: imagesEdit,
            capabilities: capabilities.map((capability) => capability._id),
            datePrompt: datePrompt,
            source: 'web',
            version: process.env.REACT_APP_WEB_VERSION,
          },
          { cancelToken: source.token }
        );

        clearTimeout(timeout);
        loadingResponse.current = false;

        if (!response.data.success) {
          if (
            response.data.error === 'no_coins' ||
            response.data.error === 'missing_coins'
          ) {
            setChat({ ...previousChat });
            toastReward({
              title: 'Missing coins',
              message: 'Recharge your account on the profile page',
              linkText: "It's quick and easy. Click here!",
              linkUrl: '/my-profile/pricing',
              image: '/gifs/coin.gif',
              time: 10000,
            });
          } else {
            toast.error(response.data.error);
          }
        } else {
          setChatUrlValue(response.data.hash);

          const currentChat = selectedChat;
          const obj = {
            ...currentChat?.chat,
            _id: response.data.hash,
            title: response.data.title,

            messages: [
              Array.isArray(response.data.message)
                ? [...response.data.message]
                : { ...response.data.message },
              ...(currentChat?.chat?.messages ?? []),
            ],
          };
          setChat(obj);

          if (obj.messages.length) {
            countTotalWords(obj.messages);
          }

          userMessage.current = null;
          setParams({});
          setBehaviours([]);
          setAttrs({});
          setCurrentPrompt(null);
          updateDummyMessage(selectedModels);

          const lastMsg = obj.messages[0];
          if (Array.isArray(lastMsg) && lastModel.current) {
            const modelId = lastMsg.filter(
              (m) => m.modelRef == lastModel.current
            );
            if (modelId.length > 0) {
              await selectedOutput(modelId[0]._id);
              lastModel.current = modelId[0].modelRef;
            }
          }
        }
      } catch (error) {
        if (error?.response?.data?.error?.message) {
          toast(error?.response?.data?.error?.message, {
            icon: '⚠️',
          });
        }

        if (!stopped.current) {
          setChat({
            ...selectedChat?.chat,
            messages: [
              ...(selectedChat?.chat?.messages ?? []).filter(
                (m) => m.date != date
              ),
            ],
          });
        } else {
          setLoading(true);
          loadingResponse.current = true;
          await store.refreshTokenAndProfileCoins();
          await getChat(selectedChat.chat._id);
          setLoading(false);
          loadingResponse.current = false;
        }

        console.error(error);
      } finally {
        setLoading(false);
        loadingResponse.current = false;
      }
    };

    // on Stop message
    const onStop = () => {
      store.stopStreamChat(
        selectedChat?.chat._id ? selectedChat?.chat._id : chatId.current
      );
      axiosSource.cancel();
      stopped.current = true;
    };

    const visionInput = (textValue = undefined) => {
      const imagesMessages = [];
      for (let image of imagesPreview) {
        imagesMessages.push({
          type: 'image_url',
          image_url: { url: image.url, words: image.words },
        });
      }
      return [
        ...imagesMessages,
        { type: 'text', text: textValue ? textValue : inputValue },
      ];
    };

    /**
     * This is the logic to manage the shared tools
     */
    useTemplateShare(store.api, openModalTemplate, getToolsAndFilterSidebar);
    // ---------------------------------------------------

    useEffect(() => {
      getToolsAndFilterSidebar('');
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // ----------------------------
    //  Modals functions
    // ----------------------------
    const onDelete = async () => {
      const arrCopy = Array.from(chatList);
      const objWithIdIndex = arrCopy.findIndex((obj) => obj._id === toDelete);
      arrCopy.splice(objWithIdIndex, 1);
      try {
        removeChatUrlValue();
        await store.api.delete('/chat/' + toDelete);
        cleanSelectedChat();
        setChat({ _id: null, messages: [] });
        setTreeData(
          treeData.filter((item) => item.id !== selectedChat.chat._id)
        );
        setChatsList(arrCopy);

        dummy.current = { _id: null, messages: [] };
      } catch (error) {
        console.error(error);
      } finally {
        onNewChat();
        setToDeleteDND(null);
        setShowDeleteDialog(false);
        setDeleted(true);
        setToDelete(null);
      }
    };

    const onClear = async () => {
      try {
        const messageIds = selectedChat.chat.messages.flatMap((message) =>
          Array.isArray(message)
            ? message.map((msg) => msg._id).filter((id) => id !== undefined)
            : message._id !== undefined
            ? [message._id]
            : []
        );

        if (messageIds.length === 0) {
          toast.error('No valid messages found to delete.');
          return;
        }

        const deletePromises = messageIds.map((messageId) =>
          store.api.delete(`/chat/${selectedChat.chat._id}/${messageId}`)
        );

        const responses = await Promise.all(deletePromises);

        const allSuccess = responses.every((response) => response.data.success);

        if (allSuccess) {
          const updatedMessages = selectedChat.chat.messages.map((message) =>
            Array.isArray(message)
              ? message.map((msg) => ({ ...msg, hidden: true }))
              : { ...message, hidden: true }
          );

          await store.api
            .delete(`/chat/${selectedChat.chat._id}/title`)
            .then(async () => {
              const chat = selectedChat.chat;

              const oldData = treeData.filter((item) => item.id !== chat._id);
              const selectedChatTree = treeData.find(
                (item) => item.id === chat._id
              );
              setTreeData([...oldData, { ...selectedChatTree, text: '' }]);
            });

          const updatedChat = {
            ...selectedChat.chat,
            messages: updatedMessages,
            title: '',
          };

          setSelectedChat(updatedChat);

          toast.success('All messages have been cleared.');
        } else {
          toast.error('Failed to clear the chat.');
        }
      } catch (error) {
        console.error('Error clearing messages:', error);
        toast.error('Error clearing messages.');
      } finally {
        setShowClearDialog(false);
        setCleared(true);
      }
    };

    const onEdit = (e) => {
      if (e.title) {
        const arrCopy = Array.from(chatList);
        const objWithIdIndex = arrCopy.findIndex(
          (obj) => obj._id === selectedChat?.chat?._id
        );
        const copyData = {
          ...arrCopy[objWithIdIndex],
          title: e.title,
        };

        setChatsList(copyData);
        setChat({ ...selectedChat?.chat, title: e.title });
      }
      setShowEditDialog(false);
    };
    // ----------------------------
    // Debounce
    const debouncedValueText = useDebounce(inputValue, 200);

    /**
     * Template chat list logic
     */
    useMenuActionsTextFieldLogic(
      store,
      debouncedValueText,
      promptListRef,
      renderType,
      setRenderType,
      setOpenMenuAction,
      TABS,
      ROWS,
      isMobile,
      setShowMobileChats
    );

    // handle key press
    const onEnterPress = (e) => {
      const totalItemsMenuAction =
        visibleOptions.length + templatesChatList.length;
      if (openMenuAction) {
        switch (e.key) {
          case 'ArrowDown':
            e.preventDefault();
            setActivePromptIndex(
              (prevIndex) => (prevIndex + 1) % totalItemsMenuAction
            );
            break;
          case 'ArrowUp':
            e.preventDefault();
            setActivePromptIndex(
              (prevIndex) =>
                (prevIndex + totalItemsMenuAction - 1) % totalItemsMenuAction
            );
            break;
          case 'Tab':
            e.preventDefault();
            setActivePromptIndex(
              (prevIndex) => (prevIndex + 1) % totalItemsMenuAction
            );
            break;
          case 'Enter':
            e.preventDefault();
            handleKeyDownEnter();
            setOpenMenuAction(false);
            break;
          case 'Escape':
            e.preventDefault();
            setOpenMenuAction(false);
            break;
          default:
            setActivePromptIndex(0);
            break;
        }
      } else if (!isMobile && e.key === 'Enter' && !e.shiftKey) {
        e.preventDefault();
        setCancel(false);
        onSend();
      }
    };
    const { setShareLink } = useShareLinkTool();
    const getShareLink = async (id) => {
      try {
        const res = await store.api.get(`/shareTools/byId/${id}`);
        const data = await res.data;
        let url = `${config.baseFrontUrl}/share/tool/${data?._id}`;
        if (store.profile.nickname_fpr)
          url = `${url}?fpr=${store.profile.nickname_fpr}`;

        setShareLink(url);
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
      }
    };

    useEffect(() => {
      if (selectedTool) {
        getPromptsByTool(selectedTool._id).then((prompts) => {
          setOptionsPrompt(prompts);
          if (prompts.length === 1) {
            setPromptValue('');
            setSelectedPrompt(
              selectedTool.prompts.filter(
                (prompt) => prompt.title === prompts[0].name
              )[0]
            );
            const value = prompts[0].value;
            setPromptValue((prevContent) => {
              let updatedContent;
              if (prevContent.includes('/')) {
                updatedContent = prevContent?.replace(/^\/.*/, value);
              } else {
                updatedContent = value;
              }
              return updatedContent;
            });
          }
        });
        getShareLink(selectedTool._id);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedTool]);
    // handleSendPrompt
    const { focus } = useTextAreaFocus();
    const checkTemplateModel = async (
      updatedVariables,
      templateData,
      files,
      runSampleAuto
    ) => {
      // Send fields context to the chat
      if (files.length > 0) await addReferenceFile(files);
      toolData.current = { updatedVariables, templateData };

      let isSelected = false;

      if (templateData) {
        if (templateData.use_tool) {
          isSelected = true;
        } else if (templateData.multi_default_models.length > 0) {
          isSelected = templateData.multi_default_models?.every((model) =>
            selectedModels.some(
              (selectedModel) => model.id === selectedModel._id
            )
          );
        } else if (templateData.default_model) {
          isSelected = selectedModels.every(
            (m) => m._id == templateData.default_model.id
          );
        }
      }

      if (isSelected) {
        handleSubmitModal(
          updatedVariables,
          templateData,
          templateData.useTool ? false : true,
          runSampleAuto,
          files
        );
      } else {
        setShowWarningDialog(true);
      }
    };

    const handleSubmitModal = (
      updatedVariables,
      templateData,
      useTemplateModels,
      runSampleAuto = false,
      files
    ) => {
      setShowWarningDialog(false);
      toolData.current = null;

      const models =
        useTemplateModels && currentTemplateModels?.length > 0
          ? currentTemplateModels
          : selectedModels;
      setSelectedModels(models);

      let newContent =
        templateData && templateData.use_tool
          ? templateData.prompt_values.label
          : templateData.prompt_values.value;

      setInputValue('');
      setParams({});
      setBehaviours([]);
      setAttrs({});
      setCurrentPrompt(templateData.prompt_values);

      updatedVariables.forEach(([key, value]) => {
        const attrs = templateData.prompt_values.attr.filter((item) =>
          typeof item == 'object' ? item.name == key : item == key
        );

        if (attrs.length > 0) {
          const attr = attrs[0];

          if (typeof attr == 'object' && attr.config) {
            switch (attr.config) {
              case 'param':
                setParams((prev) => ({ ...prev, [key]: value }));
                break;
              case 'attr':
                setAttrs((prev) => ({ ...prev, [key]: value }));
                break;
              default:
                break;
            }
          }

          if (typeof attr == 'object' && attr.behaviour) {
            setBehaviours((prev) => [...prev, { target: key, value: value }]);
          }
        }

        if (typeof value !== 'object' && value !== '' && value) {
          newContent = newContent?.replaceAll(
            '${' + key + '}',
            `"'${value.trim()}'"`
          );
        }
      });

      setInputValue(newContent);
      focus();
      // This line sends the values to track the prompt usages
      setValuesAfterInsert(templateData.prompt_values, templateData);
      if (runSampleAuto) {
        let modelsIds =
          selectedTool?.multi_default_models.length > 0
            ? selectedTool?.multi_default_models.map((model) => model.id)
            : selectedTool?.default_model
            ? [selectedTool?.default_model?.id]
            : undefined;
        onSend(null, newContent, modelsIds, files);
      }
    };

    // handleCloseTemplateModal
    const handleCloseTemplateModal = (defaultParams) => {
      // remove the states
      setTemplateData(null);
      setSelectedTool(null);
      setSelectedPrompt(null);
      setActivePromptIndex(null);
      toggleShowPromptModal();

      // Create params
      const params = new URLSearchParams(location.search);
      params.delete('selectedTool');

      // delete the params
      if (defaultParams) {
        Object.entries(defaultParams).forEach(([key]) => {
          params.delete(key);
        });
      }

      // remove the selected tool from the url
      navigate(
        {
          pathname: location.pathname,
          search: params.toString(),
        },
        { shallow: true }
      );
      fetchFavs();
      addEvent(LOADING_FAV_TOOLS);
    };

    //Model by template default model
    const handleTemplateModel = async (modelsId) => {
      if (modelsId && modelsId.length > 0) {
        const queryString = modelsId
          .map((modelId) => `default_model_template=${modelId}`)
          .join('&');
        try {
          const response = await store.api.get(`/model/byId?${queryString}`);
          setCurrentTemplateModels(response.data.model);
        } catch (error) {
          console.log(error);
        }
      }
    };

    const visibleOptions = filteredOptions.filter((option) => !option.isHidden);

    const functionMap = visibleOptions.reduce((acc, option, idx) => {
      let actualIndex = idx;
      acc[actualIndex] = (() => {
        if (option.name === 'New Chat') {
          return onNewChat;
        } else if (option.name === 'Select Model') {
          return () => setShowModelsDialog(true);
        } else if (option.name === 'Text To Speech') {
          return () => handleScriptForTTS([]);
        } else if (option.name === 'Clear Chat') {
          return () => setShowClearDialog(true);
        } else {
          return handleShowMobilePrompts;
        }
      })();
      return acc;
    }, {});

    // handle init modal
    const handleKeyDownEnter = () => {
      if (activePromptIndex == null || activePromptIndex === -1) {
        return;
      }
      // Execute the function of actions
      if (activePromptIndex in functionMap) {
        functionMap[activePromptIndex]();
        // Execute the function of the templates
      } else {
        const templateIndex = activePromptIndex - visibleOptions.length;
        const selectedToolLocal = templatesChatList[templateIndex];

        setSelectedTool(selectedToolLocal);
        toggleShowPromptModal();
        setOpenMenuAction(false);
      }
    };

    const onCloseBoth = () => {
      setShowMobileChats(false);
      setShowMobilePrompts(false);
    };

    // create new chat
    onNewChat = async () => {
      setInputValue(''); // clean the input value
      lastModel.current = null;
      loadingResponse.current = false;
      cancelChat();
      setChat({ _id: null, messages: [] });
      setChatWords(0);
      removeChatUrlValue();
      userMessage.current = null;
      // setUserMessage(null);
      setShowMobileChats(false);
      setShowMobilePrompts(false);
      dummy.current = { _id: null, messages: [] };
      cleanSelectedChat();
      focus();
      setRefFile(null);
      let defaultModel = localStorage.getItem('multimodelDefault');
      //TODO cuando lo llama al principio
      if (defaultModel) {
        setSelectedModels(JSON.parse(defaultModel));
      }
      setStraicoChoiceTools(straicoChoiceTools);

      checkUseTool();
    };

    const checkUseTool = () => {
      if (!currentPrompt) {
        return;
      }

      let tool;
      const filtered = tools.filter((item) => item._id == currentPrompt.tool);

      if (filtered && filtered.length > 0) {
        tool = filtered[0];
      }

      if (tool && tool.useTool) {
        setInputValue('');
        setParams({});
        setBehaviours([]);
        setAttrs({});
        setCurrentPrompt(null);
      }
    };

    // on select chat
    const onSelectChat = (chat, messageIdentifier = null, isUser = null) => {
      cancelChat();
      // Rest methods
      userMessage.current = null;
      selectChat(chat?._id);
      setXchatId(chat?._id);
      getChat(chat?._id, messageIdentifier, isUser);
      setShowMobileChats(false);
      setRefFile(null);
      checkUseTool();
      // Stop the stream current chat
      if (loadingResponse.current) {
        store.stopStreamChat(
          selectedChat?.chat._id ? selectedChat?.chat._id : chatId.current
        );
      }
      loadingResponse.current = false;
    };

    // If the input value is empty, clean the latest fields
    useEffect(() => {
      if (inputValue === '') {
        cleanLastestFields();
        if (!loading) {
          setParams({});
          setBehaviours([]);
          setAttrs({});
          setCurrentPrompt(null);
        }
      }
    }, [inputValue]);

    const addReferenceFile = async (files) => {
      setLoadingBar(true);
      //TODO to send the multi extensions files
      if (
        refFile &&
        Array.isArray(refFile) &&
        refFile.length > 0 &&
        Array.isArray(files) &&
        files.length > 0
      ) {
        for (let i = 0; i < files.length; i++) {
          const attachment = files[i];
          let searchFile = refFile.find(
            (file) => file.metadata.name === attachment.name
          );

          if (searchFile) {
            // File with the same name already exists
            setShowFileDialog(false);
            setLoadingBar(false);
            toast(
              'You already uploaded a file with the name ' + attachment.name,
              {
                icon: '😱',
              }
            );
            continue;
          }
        }
      }

      for (let i = 0; i < files.length; i++) {
        let e = files[i].metadata ? files[i].metadata : files[i];
        try {
          setShowFileDialog(false);
          let response;
          if (typeof e === 'string') {
            const youtubeUrlPattern =
              /^(https?:\/\/)?(www\.)?(m\.)?(youtube\.com|youtu\.be)\/.+/i;
            if (youtubeUrlPattern.test(e)) {
              // Upload YouTube video first time
              toast('The upload process can take some seconds. Please wait.', {
                icon: '⌛️',
              });
              response = await store.api.post(`/file/youtube`, {
                url: e,
                enabled: true,
              });
            } else {
              // Upload Web page first time
              response = await store.api.post(`/file/webpage`, {
                url: e,
                enabled: true,
              });
              if (response.data.file.words <= 10) {
                toast(
                  'This web page has additional technology that unables us to access its content',
                  {
                    icon: '⛔️',
                  }
                );
                continue;
              }
            }
          } else if (e.type == 'pdf' || e.type == 'application/pdf') {
            e.type = 'pdf';
            // Load previous pdf
            response = await store.api.post(`/file`, e);
            if (response.data.file.words === 0) {
              toast(
                `The PDF (${e.name}) you are inserting is empty or couldn't be read`,
                {
                  icon: '⛔️',
                }
              );
              continue;
            }
          } else if (e.type == 'image') {
            // Load images allways
            response = await store.api.post(`/file`, e);
          } else if (e.type == 'youtube') {
            // Load previos youtube
            response = await store.api.post(`/file/youtube`, e);
          } else if (e.type == 'webpage') {
            // Load previos webpage
            response = await store.api.post(`/file/webpage`, e);
            if (response.data.file.words <= 10) {
              toast(
                'This web page has additional technology that unables us to access its content',
                {
                  icon: '⛔️',
                }
              );
              continue;
            }
          } else if (
            e.type ===
              'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
            e.type === 'docx'
          ) {
            e.type = 'docx';
            response = await store.api.post(`/file`, e);
          } else if (
            e.type ===
              'application/vnd.openxmlformats-officedocument.presentationml.presentation' ||
            e.type === 'pptx'
          ) {
            e.type = 'pptx';
            response = await store.api.post(`/file`, e);
          } else if (
            e.type ===
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
            e.type === 'xlsx'
          ) {
            toast(
              'When querying an Excel Document file, formulas nor macros will be taken into account',
              {
                icon: '👀',
                duration: 12000, // 12 seconds in milliseconds
              }
            );
            e.type = 'xlsx';
            response = await store.api.post(`/file`, e);
          } else if (e.type === 'text/plain' || e.type === 'txt') {
            e.type = 'txt';
            response = await store.api.post(`/file`, e);
          } else if (e.type === 'text/html' || e.type === 'html') {
            e.type = 'html';
            response = await store.api.post(`/file`, e);
          } else if (e.type === 'application/json' || e.type === 'json') {
            e.type = 'json';
            response = await store.api.post(`/file`, e);
          } else if (e.type === 'text/csv' || e.type === 'csv') {
            e.type = 'csv';
            response = await store.api.post(`/file`, e);
          } else if (e.type === 'audio/mpeg' || e.type === 'mp3') {
            e.type = 'mp3';
            response = await store.api.post(`/file/multimedia`, e);
          } else if (e.type === 'video/mp4' || e.type == 'mp4') {
            if (!('words' in e)) {
              toast(
                'The upload process can take several minutes. Please wait.',
                {
                  icon: '⌛️',
                }
              );
            }

            e.type = 'mp4';
            response = await store.api.post(`/file/multimedia`, e);
          } else {
            // Load core documen text/plain
            response = await store.api.post(`/file`, e);
          }

          if (e.type == 'image') {
            setImagesPreview((prevImagesPreview) => {
              if (Array.isArray(prevImagesPreview)) {
                if (
                  !prevImagesPreview.some((file) => file === response.data.file)
                ) {
                  // If it's not in the array, add the new file to it
                  return [...prevImagesPreview, response.data.file];
                } else {
                  // If it's already in the array, return the current array
                  return prevImagesPreview;
                }
              } else {
                // If it's not an array, create a new array with the current and new file
                let newarray = [response.data.file];
                return newarray;
              }
            });
          } else {
            // setRefFile(response.data.file);
            updateRefFile((prevRefFile) => {
              if (Array.isArray(prevRefFile)) {
                // Check if the file is already in the array
                if (
                  !prevRefFile.some(
                    (file) => file.file === response.data.file._id
                  )
                ) {
                  // If it's not in the array, add the new file to it
                  return [...prevRefFile, convertFile(response.data.file)];
                } else {
                  // If it's already in the array, return the current array
                  return prevRefFile;
                }
              } else {
                // If it's not an array, create a new array with the current and new file
                let newarray = [convertFile(response.data.file)];
                return newarray;
              }
            });
            toast.success(
              `The file ${e.name ? 'named ' + e.name : ''} is attached.`
            );
          }
        } catch (error) {
          if (error?.response?.data?.error?.message) {
            toast.error(error?.response?.data?.error?.message);
          }
        } finally {
          if (i === files.length - 1) setLoadingBar(false);
        }
      }
    };

    const convertFile = (file) => {
      return { file: file._id, enabled: true, metadata: { ...file } };
    };

    // -------------------------------------
    // TTS
    // -------------------------------------
    const handleScriptForTTS = (value = []) => {
      setScriptValue(value);
      setShowTextSpeechDialog(true);
    };
    const getHello = () => {
      let name = JSON.parse(localStorage.getItem('profile')).fname;
      let hiArray = [
        'Hello',
        'Hi',
        'Hi there',
        'Greetings',
        'Welcome back',
        "What's up",
        'Hey',
        'Good to see you',
      ];

      let questionArray = [
        "Here's where AI meets imagination – Let's create something amazing together!",
        'Ready to elevate your ideas with AI-powered productivity?',
        'Your next big idea starts here - Straico is here to help!',
        "Let's turn your thoughts into action!",
        'Ready to outshine? Strike up a chat and let your AI assistant do the rest',
        'What are you willing to explore with multimodal AI today?',
      ];

      // Generate random indices for greetings and questions
      const randomHiIndex = Math.floor(Math.random() * hiArray.length);
      const randomQuestionIndex = Math.floor(
        Math.random() * questionArray.length
      );

      // Get random greeting and question
      const randomHi = hiArray[randomHiIndex];
      const randomQuestion = questionArray[randomQuestionIndex];

      // Concatenate the greeting with the name
      const greetingWithUsername = `${randomHi}, ${name}`;

      // Return both values
      return [greetingWithUsername, randomQuestion];
    };

    // -------------------------------------
    // Audio input
    // -------------------------------------
    const {
      startRecording,
      stopRecording,
      recordingBlob,
      isRecording,
      recordingTime,
    } = useAudioRecorder();

    const [isVoiceProcessing, setIsVoiceProcessing] = useState(false);

    useEffect(() => {
      if (!recordingBlob) return;
      getAudio(recordingBlob);
    }, [recordingBlob]);

    const getAudio = async (blob) => {
      setLoadingBar(true);
      setIsVoiceProcessing(true);
      const data = new FormData();
      data.append('file', blob, '2260c8a3-5c42-4a1b-8503-dedd931cc835.webm');
      try {
        await store.api.post('/auth/whisper', data, {}).then(({ data }) => {
          setInputValue(data.text);
        });
      } catch (error) {
        console.error(error);
      } finally {
        setLoadingBar(false);
        setIsVoiceProcessing(false);
        toast('Your recording was successfully processed!', {
          icon: <InformationCircleIcon className="w-4 h-4 text-cool-gray" />,
        });
      }
    };

    // -------------------------------------

    const calculateCoins = (words) => {
      let totalCoins = 0;
      const wordcapLimits = store.profile.wordcap_limits;
      selectedModels.forEach((model) => {
        const pricing = model.pricing;

        let maxWords = wordcapLimits ? wordcapLimits.words : 0;
        if (maxWords === 0 || maxWords > tokensToWords(model.context_length)) {
          maxWords = Math.round(tokensToWords(model.context_length));
        }

        const input = words > maxWords ? maxWords : words;
        let imagesCost = 0;
        if (model.type == 'vision') {
          for (let img of imagesPreview) {
            imagesCost += img.words;
          }
        }
        const coins =
          (pricing.words > 0 ? (input + imagesCost) / pricing.words : 0) *
          pricing.coins;

        totalCoins += currentPrompt
          ? parseFloat(handleBehaviour(coins))
          : parseFloat(coins.toFixed(2));
      });

      return totalCoins.toFixed(2);
    };

    const tokensToWords = (tokens) => {
      return (tokens * 3) / 4;
    };

    const handleBehaviour = (coins) => {
      let tool;
      const filtered = tools.filter((item) => item._id == currentPrompt.tool);

      if (filtered && filtered.length > 0) {
        tool = filtered[0];
      }

      if (!tool || (tool && !tool.useTool)) {
        return coins.toFixed(2);
      }

      const data = currentPrompt.attr.filter(
        (item) => typeof item == 'object' && item.behaviour
      );

      if (!data || data.length == 0) {
        return coins.toFixed(2);
      }

      let finalCoins = tool.coins;
      let clonedTool = JSON.parse(JSON.stringify(tool));
      for (let behaviour of data) {
        const behaviourData = behaviours.filter(
          (obj) => obj.target == behaviour.name
        )[0];
        switch (behaviour.behaviour) {
          case 'call_times': {
            finalCoins = clonedTool.coins * behaviourData.value;
            clonedTool.coins = finalCoins;
            break;
          }
          case 'price_adjustment': {
            const rule = behaviour.rules.filter(
              (item) => item.value == behaviourData.value
            )[0];
            finalCoins = rule
              ? clonedTool.coins * rule.factor
              : clonedTool.coins;
            clonedTool.coins = finalCoins;
            break;
          }
          default:
            break;
        }
      }

      return finalCoins.toFixed(2);
    };

    const handleBehaviourLoader = () => {
      if (!tools.length) {
        getToolsAndFilter('');
      }

      let tool = selectedTool;
      const filtered = !tool
        ? tools.filter((item) => item._id == currentPrompt.tool)
        : null;

      if (filtered && filtered.length > 0) {
        tool = filtered[0];
      }

      if (!tool || (tool && !tool.useTool)) {
        return null;
      }

      const data = currentPrompt.attr.filter(
        (item) =>
          typeof item == 'object' &&
          item.behaviour &&
          item.behaviour == 'call_times'
      );

      if (data) {
        const behaviourData = behaviours.filter(
          (obj) => obj.target == data[0].name
        )[0];
        return Array(Number(behaviourData.value)).fill({
          type: 'image_url',
          image_url: {
            url: 'https://prompt-rack.s3.amazonaws.com/images/1703813690569_straico_loading-vanilla.gif',
            words: 0,
          },
          no_actions: true,
          loading: true,
        });
      }
      return [
        {
          type: 'image_url',
          image_url: {
            url: 'https://prompt-rack.s3.amazonaws.com/images/1703813690569_straico_loading-vanilla.gif',
            words: 0,
          },
          no_actions: true,
          loading: true,
        },
      ];
    };

    const changeOutput = async (element) => {
      if (element._id && !loadingResponse.current) {
        await selectedOutput(element._id);
        countTotalWords(selectedChat.chat.messages);

        // check last message
        const lastMessage = selectedChat.chat.messages[0];
        if (
          Array.isArray(lastMessage) &&
          lastMessage.map((r) => r._id).includes(element._id)
        ) {
          lastModel.current = element.modelRef;
        }
      } else {
        lastModel.current = element.modelRef;
      }
    };

    const selectedOutput = async (hash) => {
      await store.api.post(`/chat/message/${hash}`, {
        chat: selectedChat.chat._id ? selectedChat.chat._id : chatId.current,
      });
      setEditMessChange();
    };

    const getVisibleModel = () => {
      let refId;
      if (lastModel.current) {
        refId = lastModel.current;
      } else {
        let lastMsg =
          (selectChat?.chat?.messages?.length ?? 0) > 0
            ? selectChat?.chat?.messages[0]
            : null;
        if (lastMsg) {
          let filterd = Array.isArray(lastMsg)
            ? lastMsg.filter((m) => m.selected == true)
            : null;
          refId =
            filterd && filterd.length
              ? filterd[0].modelRef
                ? filterd[0].modelRef
                : null
              : lastMsg.modelRef
              ? lastMsg.modelRef
              : null;
        }
      }

      let filteredModels = refId
        ? selectedModels.filter((model) => model._id == refId)
        : null;
      return filteredModels && filteredModels.length
        ? filteredModels[0]
        : selectedModels[0];
    };

    const getChatWords = (text) => {
      let total = 0;
      if (refFile) {
        refFile.forEach((file) => {
          if (file.metadata.words && file.enabled) {
            total += file.metadata.words;
          }
        });
      }
      return total + chatWords + store.countWords(text);
    };
    const checkLimit = (text) => {
      if (
        !store.profile.wordcap_limits ||
        store.profile.wordcap_limits.words == 0
      )
        return false;
      return getChatWords(text) > store.profile.wordcap_limits.words;
    };

    // handle delete of a message
    const handleMessageDelete = (messageId) => {
      let previousUserMessageIndex = -1;

      // Map through messages to hide the target and keep track of the previous user message index
      const updatedMessages = selectedChat.chat.messages.map(
        (message, index) => {
          if (Array.isArray(message)) {
            const idx = message.findIndex((msg) => msg._id === messageId);
            if (idx !== -1) {
              if (previousUserMessageIndex === -1)
                previousUserMessageIndex = index - 1;
              // Hide all messages in this array
              return message.map((msg) => ({ ...msg, hidden: true }));
            }
          } else if (message._id === messageId) {
            previousUserMessageIndex = index - 1;
            return { ...message, hidden: true };
          }
          return message;
        }
      );

      // Hide also the previous message
      if (
        previousUserMessageIndex >= 0 &&
        !Array.isArray(updatedMessages[previousUserMessageIndex])
      ) {
        updatedMessages[previousUserMessageIndex] = {
          ...updatedMessages[previousUserMessageIndex],
          hidden: true,
        };
      }

      // Create the updated chat object
      const updatedChat = {
        ...selectedChat.chat,
        messages: updatedMessages,
      };

      setXchatId(selectedChat.chat._id);
      setSelectedChat(updatedChat);
      getChat(selectedChat.chat._id);
    };

    // handle delete of a response
    const handleResponseDelete = (messageId) => {
      let state = { isSelected: false };
      const updatedMessages = findAndModifyMessage(
        selectedChat.chat.messages,
        messageId,
        'hidden',
        true,
        state
      );

      const updatedChat = {
        ...selectedChat.chat,
        messages: updatedMessages,
      };

      setXchatId(selectedChat.chat._id);
      setSelectedChat(updatedChat);
      getChat(selectedChat.chat._id);
    };

    const findAndModifyMessage = (
      messages,
      targetId,
      attributeName,
      newValue,
      state
    ) => {
      for (let item of messages) {
        if (Array.isArray(item)) {
          findAndModifyMessage(item, targetId, attributeName, newValue, state);
          if (state.isSelected) {
            const filteredMessages = item.filter((r) => r.hidden != true);
            changeOutput(filteredMessages[0]);
            state.isSelected = false;
          }
        } else if (item._id === targetId) {
          item[attributeName] = newValue;
          state.isSelected = item.selected;
          return messages;
        }
      }
      return messages;
    };
    /**
     * Memo to render messages
     */
    const renderMessages = useMemo(() => {
      const renderComplexLogic = (selectedChat?.chat?.messages ?? []).map(
        (item, index) => {
          let temp = Array.isArray(item)
            ? item.filter((m) => m.selected == true)
            : [item];
          let every =
            (Array.isArray(item) && item.every((e) => e._id)) ||
            !Array.isArray(item);
          let realItem = temp.length && every ? temp[0] : null;
          let realKey = realItem && realItem._id ? realItem._id : dummyId();

          const isUserMessage =
            !Array.isArray(item) && item.data.role === 'user';

          return (
            <>
              <ChatMessage
                key={index == 0 && loading ? streamCode.current : realKey}
                item={item}
                index={index}
                avatar={avatar}
                chatId={xchatId}
                formatedDate={formatedDate}
                onCapturedScript={handleScriptForTTS}
                onDelete={handleMessageDelete}
                onDeleteResponse={handleResponseDelete}
                onLoading={(e) => setLoadingBar(e)}
                onOpenFull={(content) => {
                  setDialogContent(content);
                  setShowImageDialog(true);
                }}
                changeOutput={changeOutput}
                capabilities={capabilitiesList}
                onRegenerate={onRegenerate}
                onRegenerateEdit={onRegenerateEdit}
                continueResponse={continueResponse}
                loading={loading}
                optimizing={optimizing}
                calculateCoinsEdit={calculateCoinsEdit}
                onAddModels={handleAddModels}
              />
              {/* DIV FOR REFERENCE */}
              <div
                key={index}
                ref={(el) => {
                  if (el) {
                    if (isUserMessage) {
                      messageRefs.current.byDate[item.date] = el;
                    } else {
                      messageRefs.current.byId[realKey] = el;
                    }
                  }
                }}
              ></div>
            </>
          );
        }
      );

      return <>{renderComplexLogic}</>;
    }, [selectedChat, loading]);

    const handleMouseEnter = (index) => {
      setHoveredIndex(index);
    };

    const handleMouseLeave = () => {
      setHoveredIndex(null);
    };

    const removeImage = (img) => {
      let modified = imagesPreview.filter((item) => item._id !== img._id);
      setImagesPreview([...modified]);
    };

    const handleBadInteraction = (event) => {
      if (event.reason == 'bad model') {
        setShowFileDialog(false);
        setShowModelsDialog(true);
      }
    };

    const removeModel = (model) => {
      updateSelectedModels((prev) =>
        prev.filter((item) => item._id !== model._id)
      );
      localStorage.setItem(
        'currentModels',
        JSON.stringify(selectedModels.filter((item) => item._id !== model._id))
      );
      oldSelectedModels.current = sortByAttribute(
        selectedModels.filter((item) => item._id !== model._id),
        'abbreviation'
      );
      setEditMessChange();
    };
    //Pricing
    const [_, setRulesPlans] = useState([]);
    useEffect(() => {
      getRulesPlans();
    }, []);
    const getRulesPlans = async () => {
      try {
        setLoading(true);
        const res = await store.api.get('/user/constant', {
          params: {
            type: 'princing_plans',
          },
        });
        setRulesPlans(res.data.rules);
        setLoading(false);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
        setLoading(false);
      }
    };

    const handleCapabilities = (capabilities) => {
      localStorage.setItem('capabilities', JSON.stringify(capabilities));
      setCapabilities(capabilities);
      setShowModelsDialog(false);
      addEvent(CAPABILITIES_CHANGE);
    };

    // render
    // Prompts Fav Handler
    const [straicoChoiceTools, setStraicoChoiceTools] = useState([]);

    const shuffleArray = (array) => {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
      }
      return array;
    };

    useEffect(() => {
      (async () => {
        try {
          const res = await store.api.get(`/v2/templates/straico-choice`);
          setStraicoChoiceTools(shuffleArray(res.data.data));
        } catch (error) {
          console.error(error);
        }
      })();
    }, []);
    const [loadingFavTools, setLoadingFavTools] = useState(false);
    const [favTools, setFavTools] = useState([]);

    const fetchFavs = async () => {
      setLoadingFavTools(true);
      try {
        const response = await store.api.get(`/v2/templates/favorites`);
        if (response?.data && response?.data?.data) {
          const filteredTools = response?.data?.data.filter(
            (tool) => !tool.in_trash
          );
          setFavTools(sortArrayAlphabetically(filteredTools));
        } else {
          console.error(
            'No se encontraron herramientas favoritas o formato inesperado:',
            response
          );
        }
      } catch (error) {
        console.error('Error al obtener herramientas favoritas:', error);
      } finally {
        setLoadingFavTools(false);
      }
    };

    // Llamar a fetchFavs cuando el componente se monte
    useEffect(() => {
      fetchFavs();
    }, []);

    function sortArrayAlphabetically(array) {
      return array.sort((a, b) => a.title.localeCompare(b.title));
    }

    const { theme: themeValue } = useThemeStore();

    const { setOpenPromptLibrary, openPromptLibrary } = usePromptLibraryStore();
    const togglePromptLibrary = () => setOpenPromptLibrary(!openPromptLibrary);

    // GET CATLOG TO FILTERS HOOK
    useCatalog(store);

    const [showDone, setShowDone] = useState(false);

    useEffect(() => {
      if (!optimizing && showDone) {
        const timer = setTimeout(() => {
          setShowDone(false);
        }, 600);
        return () => clearTimeout(timer);
      }
    }, [optimizing, showDone]);

    const handleOptimizationComplete = () => {
      setOptimizing(false);
      setShowDone(true);
    };

    const getRenderIcon = (type, className = '', onClick) => {
      switch (type) {
        case TABS:
          return <TabsIcon className={className} onClick={onClick} />;
        case COLUMNS:
          return <ColumnsIcon className={className} onClick={onClick} />;
        case ROWS:
          return <RowsIcon className={className} onClick={onClick} />;
        case GRID:
          return <GridIcon className={className} onClick={onClick} />;
        default:
          break;
      }
    };

    const renderTypes = () => {
      const types = [
        { label: 'Columns layout', type: COLUMNS, icon: ColumnsIcon },
        { label: 'Rows layout', type: ROWS, icon: RowsIcon },
        { label: 'Grid layout', type: GRID, icon: GridIcon },
      ];
      return maxMessageModels > 2 || selectedModels.length > 2
        ? types
        : types.filter((e) => e.type != GRID);
    };

    return (
      <>
        <Helmet>
          <title>Chat - Straico</title>
        </Helmet>

        <CoinsAlertModal
          handleConfirm={handleConfirmAlertCoins}
          customClose={handleCloseAlertCoins}
        />

        {/* Chat */}
        <div
          className={`flex flex-col h-full w-full flex-1 items-center overflow-auto`}
        >
          {loadingBar && <LinearProgress />}

          <div className="flex-1 flex flex-col w-full h-full overflow-auto font-figtree">
            <div
              className={`h-full w-full bg-seasalt dark:bg-lead flex-1 flex flex-col overflow-auto ${
                powerPanelOpen
                  ? 'md:max-w-[calc(100vw_-_16rem)]'
                  : 'md:max-w-[calc(100vw_-_3.5rem)]'
              }`}
            >
              {!((selectedChat?.chat?.messages.length || 0) > 0) && (
                <div className="mx-auto w-full lg:max-w-4xl">
                  <AttachedFiles
                    files={refFile}
                    onFileChange={updateRefFile}
                    setShowFileDialog={setShowFileDialog}
                  />
                </div>
              )}
              {(selectedChat.chat?.messages?.length || 0) > 0 ||
              selectedChat.chat?._id ? (
                <div
                  className={`flex flex-col flex-1 max-w-full h-full ${
                    themeValue == 'dark' ? 'bg-lead' : 'bg-seasalt'
                  }`}
                >
                  {/* Title chat - Top Bar */}
                  {(selectedChat?.chat?.messages?.length > 0 ||
                    selectedChat?.chat?.title) && (
                    <AnimatePresence>
                      {headerVisible && (
                        <motion.div
                          initial="hidden"
                          animate="visible"
                          exit="hidden"
                          variants={{
                            hidden: { height: 0, opacity: 0 },
                            visible: { height: 32, opacity: 1 },
                          }}
                          transition={{ duration: 0.5 }}
                          className="flex px-2 mx-auto w-full lg:max-w-4xl text-violet-blue dark:text-stargate-shimmer bg-lavender h-8 rounded-lg dark:bg-ship-grey"
                        >
                          <div
                            onClick={goBackChat}
                            className="w-10 p-1 flex items-center justify-center hover:bg-ghost-white dark:hover:bg-palladium rounded cursor-pointer lg:hidden"
                          >
                            <ArrowLeftIcon className="h-5 w-5" />
                          </div>

                          {/* Side by side controls */}
                          <div className="hidden md:flex items-center mr-3">
                            {renderType == TABS ? (
                              <>
                                {/* Side by side view */}
                                <Tooltip title="Side by side" arrow>
                                  <div>
                                    <TabsIcon
                                      className="cursor-pointer select-none mx-[10px] w-5 h-5"
                                      onClick={() => setRenderType(COLUMNS)}
                                    />
                                  </div>
                                </Tooltip>
                              </>
                            ) : (
                              <>
                                {/* Tabs view */}
                                <Tooltip title="Tabs layout" arrow>
                                  <div>
                                    {getRenderIcon(
                                      renderType,
                                      'cursor-pointer select-none mx-[10px] w-5 h-5',
                                      () => setRenderType(TABS)
                                    )}
                                  </div>
                                </Tooltip>
                                {(maxMessageModels > 1 ||
                                  selectedModels.length > 1) && (
                                  <div className="flex gap-2 max-h-min px-2 py-1 rounded-lg bg-ghost-white text-cool-gray dark:bg-lead dark:text-stargate-shimmer">
                                    {renderTypes().map((type, index) => (
                                      <Tooltip
                                        key={index}
                                        title={type.label}
                                        arrow
                                      >
                                        <div>
                                          <type.icon
                                            className="w-4 h-4 cursor-pointer select-none"
                                            onClick={() =>
                                              setRenderType(type.type)
                                            }
                                          />
                                        </div>
                                      </Tooltip>
                                    ))}
                                  </div>
                                )}
                              </>
                            )}
                          </div>

                          <div className="w-full flex-1 flex items-center justify-center line-clamp-1">
                            <p className="text-base font-semibold leading-6 line-clamp-1">
                              {selectedChat.chat.title}
                            </p>
                          </div>
                          {!selectedChat?.chat?.isShared && (
                            <>
                              <ShareChat
                                chat={selectedChat.chat}
                                renderChat={renderMessages}
                                capabilities={capabilities}
                              />
                              <Tooltip title="Delete" arrow>
                                <IconButton
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setDeleted(false);
                                    setShowDeleteDialog(true);
                                    setToDelete(selectedChat?.chat?._id);
                                  }}
                                >
                                  <DeleteIcon
                                    className={
                                      'h-5 w-5 text-violet-blue dark:text-stargate-shimmer'
                                    }
                                  />
                                </IconButton>
                              </Tooltip>
                              <Tooltip title="Edit name" arrow>
                                <IconButton
                                  onClick={() => setShowEditDialog(true)}
                                >
                                  <EditIcon
                                    className={
                                      'h-5 w-5 text-violet-blue dark:text-stargate-shimmer'
                                    }
                                  />
                                </IconButton>
                              </Tooltip>
                              <Tooltip title="Clear" arrow>
                                <IconButton
                                  onClick={() => setShowClearDialog(true)}
                                >
                                  <ClearIcon
                                    className={
                                      'h-5 w-5 text-violet-blue dark:text-stargate-shimmer'
                                    }
                                  />
                                </IconButton>
                              </Tooltip>
                              <Tooltip
                                title={hideOnHover ? 'Show' : 'Hide'}
                                arrow
                              >
                                <IconButton
                                  onClick={() => setHideOnHover(!hideOnHover)}
                                >
                                  {hideOnHover ? (
                                    <EyeSlashIcon
                                      className={
                                        'h-5 w-5 text-violet-blue dark:text-stargate-shimmer stroke-2'
                                      }
                                    />
                                  ) : (
                                    <EyeIcon
                                      className={
                                        'h-5 w-5 text-violet-blue dark:text-stargate-shimmer stroke-2'
                                      }
                                    />
                                  )}
                                </IconButton>
                              </Tooltip>
                            </>
                          )}
                        </motion.div>
                      )}
                    </AnimatePresence>
                  )}
                  <div className="mx-auto w-full lg:max-w-4xl">
                    <AttachedFiles
                      files={refFile}
                      onFileChange={updateRefFile}
                      setShowFileDialog={setShowFileDialog}
                    />
                  </div>
                  {/* Messages between AI and user */}
                  <div
                    ref={chatContainer}
                    className="overflow-auto flex flex-col-reverse overflow-rtl overflow-rtl-light dark:overflow-rtl-dark"
                    onScroll={handleScroll}
                  >
                    {loading &&
                      (smartMode === 'on' ? (
                        <div className="py-8 w-full flex items-center justify-center">
                          {optimizing ? (
                            <>
                              <Loader className="h-8 mr-2" />
                              <span className="text-sm text-nue-blue dark:text-tropical-indigo font-semibold">
                                Optimizing your prompt...
                              </span>
                            </>
                          ) : showDone ? (
                            <>
                              <Loader className="h-8 mr-2" />
                              <span className="text-sm text-nue-blue dark:text-tropical-indigo font-semibold">
                                Done!
                              </span>
                            </>
                          ) : (
                            <Loader className="h-8" />
                          )}
                        </div>
                      ) : currentPrompt &&
                        (currentPrompt.type === 'image' ||
                          currentPrompt.type === 'fluxImage') ? (
                        <ChatMessage
                          item={{
                            data: {
                              role: 'assistant',
                              content: handleBehaviourLoader(),
                            },
                            model: currentPrompt?.abbreviation,
                            date: moment().utc(),
                          }}
                          formatedDate={formatedDate}
                          readOnlyMessage
                          loading
                          optimizing
                        />
                      ) : (
                        <div className="py-8 w-full flex items-center justify-center">
                          <Loader className="h-8" />
                        </div>
                      ))}
                    {/** Render the memo messages */}
                    {renderMessages}
                  </div>
                </div>
              ) : (
                <>
                  <div
                    className={`flex overflow-rtl ${
                      themeValue == 'dark'
                        ? 'bg-lead overflow-rtl-dark'
                        : 'bg-seasalt overflow-rtl-light'
                    } overflow-auto flex-col h-full font-figtree px-9 justify-around lg:max-w-4xl w-full m-auto`}
                  >
                    <section className="mb-6">
                      <article>
                        <h2
                          className={`text-[28px] font-bold leading-[28px] ${
                            themeValue == 'dark' ? 'text-white' : 'text-black'
                          }`}
                        >
                          {welcomeMessage[0]}
                        </h2>
                        <p
                          className={`text-xl ${
                            themeValue == 'dark' ? 'text-white' : 'text-black'
                          }`}
                        >
                          {welcomeMessage[1]}
                        </p>
                      </article>
                    </section>

                    {isMobile ? (
                      favTools.length > 0 ? (
                        <ScrollableToolsSection
                          title={
                            favTools.length === 0
                              ? 'No favorite templates'
                              : favTools.length === 1
                              ? 'Your favorite template'
                              : 'Your favorite templates'
                          }
                          tools={favTools}
                          isSmallMobile={true}
                          subTitle={
                            favTools.length === 0
                              ? "To add a prompt template to your favorites, simply click on the star ⭐️ on the template's description."
                              : ''
                          }
                        />
                      ) : (
                        <ScrollableToolsSection
                          title="Generate images with top AI models"
                          tools={straicoChoiceTools}
                          isSmallMobile={true}
                        />
                      )
                    ) : (
                      <>
                        <ScrollableToolsSection
                          title={
                            favTools.length === 0
                              ? 'No favorite templates'
                              : favTools.length === 1
                              ? 'Your favorite template'
                              : 'Your favorite templates'
                          }
                          tools={favTools}
                          isSmallMobile={true}
                          subTitle={
                            favTools.length === 0
                              ? "To add a prompt template to your favorites, simply click on the star ⭐️ on the template's description."
                              : ''
                          }
                        />
                        <ScrollableToolsSection
                          title="Generate images with top AI models"
                          tools={straicoChoiceTools}
                          isSmallMobile={false}
                        />
                      </>
                    )}
                    <div
                      className={`flex flex-col w-full justify-end ${
                        themeValue == 'dark'
                          ? 'text-stargate-shimmer'
                          : 'text-nue-blue'
                      } mt-2 items-end cursor-pointer`}
                    >
                      <div className="flex items-center gap-3">
                        <p
                          className="text-base leading-7 font-bold"
                          onClick={togglePromptLibrary}
                        >
                          Explore our prompt collection
                        </p>
                        <ArrowLongRightIcon className="h-4 w-4" />
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>

            {/* Input field */}
            <div
              ref={inputContainerRef}
              className={`relative m-auto w-full p-3 pb-2 flex items-start lg:max-w-4xl ${
                themeValue == 'dark' ? 'bg-lead' : 'bg-seasalt'
              }`}
            >
              <div
                style={{
                  transform: 'rotate(-90deg)',
                  marginLeft: window.innerWidth < 768 ? '-10px' : '-25px',
                  marginRight: window.innerWidth < 768 ? '-15px' : '-30px',
                  marginTop: window.innerWidth < 768 ? '10px' : '15px',
                }}
              >
                <ToggleSmartMode />
              </div>
              {isRecording && (
                <div className="flex flex-col mt-2">
                  <div className="p-0">
                    <img
                      alt="record-gif"
                      src="/gifs/rec.gif"
                      className="h-6 w-6 m-auto justify-center"
                    />
                  </div>
                  <div
                    className={`px-1 font-figtree font-normal text-sm leading-5 text-center ${
                      themeValue == 'dark' ? 'text-crystal-bell' : 'text-lead'
                    }`}
                  >
                    {formatTime(recordingTime)}
                  </div>
                </div>
              )}
              <ActionsChat
                loading={loading}
                chat={selectedChat?.chat}
                onStop={onStop}
              />
              {!isRecording && (
                <div className="max-md:flex hidden h-8 justify-center flex-col items-center">
                  <PaperClipIcon
                    className="max-md:block hidden w-8 h-8 text-agate-violet cursor-pointer select-none scale-x-[-1] rotate-45"
                    onClick={() => setShowFileDialog(true)}
                  />
                </div>
              )}
              <label
                htmlFor="user-message"
                className="-mt-1 px-2 relative transition block w-full"
              >
                {inputValue?.length > 0 && (
                  <div
                    className="absolute top-3 max-md:right-16 right-[140px] z-50 cursor-pointer"
                    onClick={() => setInputValue('')}
                  >
                    <XMarkIcon
                      className={`w-4 ${
                        themeValue == 'dark'
                          ? 'text-stargate-shimmer'
                          : 'text-violet-blue'
                      }`}
                    />
                  </div>
                )}
                <TextAreaHightlight
                  classNameContainer={`outline-none focus:outline-none text-md rounded w-full font-regular ${
                    inputError ? 'border-red-400' : 'border-nue-blue'
                  } font-figtree shadow-none text-base`}
                  textareaRef={textareaRef}
                  autoFocus
                  minRows={1}
                  maxRows={7}
                  onKeyDown={onEnterPress}
                  value={inputValue}
                  onChange={(value) => {
                    setInputValue(value);
                  }}
                  highlightWords={
                    themeValue == 'dark'
                      ? highlightWordsTextAreaV2Dark
                      : highlightWordsTextAreaV2
                  }
                  inputWarning={checkLimit(inputValue)}
                  warningAction={onNewChat}
                  onInteraction={(e) => addReferenceFile(e)}
                  models={selectedModels}
                  setShowFileDialog={setShowFileDialog}
                  startRecording={startRecording}
                  stopRecording={stopRecording}
                  onSend={onSend}
                  triggerAnimation={triggerAnimation}
                >
                  <div className="flex gap-3 px-[0.625rem]">
                    {imagesPreview.length > 0 &&
                      imagesPreview.map((image, index) => (
                        <div
                          key={index}
                          className="mt-3 relative cursor-pointer"
                          onMouseEnter={() => handleMouseEnter(index)}
                          onMouseLeave={handleMouseLeave}
                        >
                          <img
                            src={image.thumbnail ? image.thumbnail : image.url}
                            alt={'Preview-' + index}
                            className="w-16 h-16 rounded object-cover"
                          />
                          <div
                            className={`absolute -top-[0.625rem] -right-[0.625rem] ${
                              hoveredIndex === index ? 'visible' : 'invisible'
                            }`}
                          >
                            <XCircleIcon
                              className={`w-5 h-5 ${
                                themeValue == 'dark'
                                  ? 'text-crystal-bell'
                                  : 'text-raisin-black'
                              }`}
                              onClick={() => removeImage(image)}
                            />
                          </div>
                        </div>
                      ))}
                  </div>
                </TextAreaHightlight>
                <MenuActionsTextField
                  ref={promptListRef}
                  filteredOptions={filteredOptions}
                  handleScriptForTTS={handleScriptForTTS}
                  setShowClearDialog={setShowClearDialog}
                  setShowModelsDialog={setShowModelsDialog}
                />
                <TemplateModal
                  onClose={handleCloseTemplateModal}
                  handleSubmitModal={checkTemplateModel}
                  handleTemplateModel={handleTemplateModel}
                />
                <DeleteTemplateModal />
                {selectedModels.length > 0 && (
                  <div className="flex gap-1 mt-1 font-figtree text-xs items-center justify-end">
                    {isVoiceProcessing && (
                      <div
                        className={`w-full h-3.5 flex items-center justify-center animate-pulse rounded ${
                          themeValue === 'dark'
                            ? 'bg-[#7379FF]'
                            : 'bg-[#464BBA]'
                        } relative`}
                      >
                        <span
                          className={`font-bold text-[8px] ${
                            themeValue === 'dark'
                              ? 'text-[#F7F7F8]'
                              : 'text-[#EFEFEF]'
                          }`}
                        >
                          LOADING...
                        </span>
                      </div>
                    )}
                    <div
                      className={`${
                        themeValue == 'dark'
                          ? 'text-sonic-silver '
                          : 'text-cool-gray '
                      } font-medium`}
                    >
                      <span
                        className={`${
                          getChatWords(inputValue) >
                            getVisibleModel().words_text && 'text-red-600'
                        }`}
                      >
                        {getChatWords(inputValue).toLocaleString('en-US')}
                      </span>
                      /{getVisibleModel().words_text.toLocaleString('en-US')}
                    </div>
                    <Shortcut
                      className={`rounded-full cursor-pointer relative group flex flex-col items-center group ${
                        checkLimit(inputValue)
                          ? 'text-red-600'
                          : themeValue == 'dark'
                          ? 'text-sonic-silver'
                          : 'text-cool-gray'
                      }`}
                      onClick={() => setShowLimitDialog(true)}
                    >
                      <InformationCircleIcon className="w-4 h-4" />
                    </Shortcut>
                    <div
                      className={`flex flex-row items-center px-[2px] ${
                        checkLimit(inputValue)
                          ? 'bg-red-600'
                          : themeValue == 'dark'
                          ? 'bg-sonic-silver text-lead'
                          : 'bg-cool-gray text-white'
                      } rounded-[20px] font-semibold py-[2px]`}
                    >
                      <CurrencyDollarIcon className="w-4 h-4" />
                      <span className="mx-1">
                        {calculateCoins(getChatWords(inputValue))}
                      </span>
                    </div>
                  </div>
                )}
              </label>

              {inputValue?.length == 0 && (
                <div className="max-md:flex hidden h-12 justify-center flex-col items-center">
                  {isRecording ? (
                    <StopIcon
                      className="w-9 h-9 text-red-500 cursor-pointer select-none"
                      onClick={() => stopRecording()}
                    />
                  ) : (
                    <MicrophoneIcon
                      className="w-9 h-9 text-agate-violet cursor-pointer select-none"
                      onClick={() => startRecording()}
                    />
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
        {showDeleteDialog && (
          <DeleteChat
            deleted={deleted}
            setDeleted={setDeleted}
            onDelete={onDelete}
            onClose={() => setShowDeleteDialog(false)}
          />
        )}
        {showWarningDialog && (
          <ModelWarningDialog
            data={toolData.current}
            onContinue={(data) =>
              handleSubmitModal(data.updatedVariables, data.prompt, true)
            }
            onContinueWithCurrentModels={(data) =>
              handleSubmitModal(data.updatedVariables, data.prompt, false)
            }
            onClose={() => setShowWarningDialog(false)}
          />
        )}
        <WordcapLimitsDialog
          open={showLimitDialog}
          exceeded={checkLimit(inputValue)}
          onClose={() => setShowLimitDialog(false)}
        />
        {showEditDialog && (
          <EditTitle chat={selectedChat.chat} onInteraction={onEdit} />
        )}
        {showFileDialog && (
          <FileContext
            onInteraction={(e) => addReferenceFile(e)}
            onClose={() => setShowFileDialog(false)}
            models={selectedModels}
            onBadInteraction={handleBadInteraction}
          />
        )}
        {showTextSpeechDialog && (
          <TextSpeechDialog
            onClose={() => setShowTextSpeechDialog(false)}
            script={scriptValue}
          />
        )}
        {showModelsDialog && (
          <ModelDialog
            selectedModels={selectedModels}
            onInteraction={(models, newModels) => {
              if (addResponseModels) {
                setSelectedModels([...models, ...newModels]);
                onAddModels(newModels);
              } else {
                setSelectedModels(models);
              }
              setShowModelsDialog(false);
            }}
            onClose={() => setShowModelsDialog(false)}
            onCapabilities={handleCapabilities}
            selectedCapabilities={capabilities}
          />
        )}
        {showClearDialog && (
          <ClearChat
            cleared={cleared}
            setCleared={setCleared}
            onClear={onClear}
            onClose={() => setShowClearDialog(false)}
          />
        )}
        {showImageDialog && (
          <ImageDialog onClose={() => setShowImageDialog(false)}>
            {dialogContent}
          </ImageDialog>
        )}
        <PromptLibrary />
        <FavoriteMessages
          onSelectChat={onSelectChat}
          avatar={avatar}
          onLoading={(e) => setLoadingBar(e)}
        />
      </>
    );
  })
);

export { onNewChat };
export default Chat;
