export function formatNumberLikeSocialMedia(number) {
  if (number < 1000) {
    return number.toString();
  } else if (number < 1_000_000) {
    return (number / 1000).toFixed(1).replace(/\.0$/, '') + 'k';
  } else if (number < 1_000_000_000) {
    return (number / 1_000_000).toFixed(1).replace(/\.0$/, '') + 'M';
  } else {
    return (number / 1_000_000_000).toFixed(1).replace(/\.0$/, '') + 'B';
  }
}
