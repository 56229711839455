// MUI
import { Button } from '@mui/material';
// Icons
import { PencilSquareIcon, TrashIcon } from '@heroicons/react/24/outline';
// Components
import CommonModal from '../CommonModal';

const ModalConfirmClose = ({
  open,
  onClose,
  onCancel,
  title = 'Work Not Saved!',
  description = 'Exiting now will discard any changes made to your template. Are you sure you want to proceed?',
  confirmButtonText = 'Discard & exit',
  cancelButtonText = 'Continue editing',
  withCloseButton = false,
}) => {
  //Method's
  const handleCancel = () => {
    onCancel();
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <CommonModal
      open={open}
      handleClose={() => {}}
      sx={{
        minHeight: 'initial',
      }}
      withoutPadding
      withCloseButton={withCloseButton}
    >
      <div className="h-full flex flex-col justify-center items-center gap-5 font-figtree text-raisin-black dark:text-crystal-bell px-[30px] py-7">
        <div className="text-2xl leading-7 font-bold">{title}</div>
        <div className="w-96 text-base font-normal leading-6 text-raisin-black dark:text-crystal-bell text-center">
          {description}
        </div>
        <div className="flex w-full gap-2 items-center justify-between pt-2">
          <Button
            variant="contained"
            color="primary"
            onClick={handleClose}
            startIcon={<TrashIcon className="size-4" />}
          >
            {confirmButtonText}
          </Button>
          <Button
            color="primary"
            onClick={handleCancel}
            variant="filled"
            startIcon={<PencilSquareIcon className="size-4" />}
          >
            {cancelButtonText}
          </Button>
        </div>
      </div>
    </CommonModal>
  );
};

export default ModalConfirmClose;
