import React, { useMemo } from 'react';
// Icons
import { Bars2Icon, PencilIcon, TrashIcon } from '@heroicons/react/24/outline';

import FolderIcon from '../../../../Theme/icons/folderClosedIcon';
import FolderOpenIcon from '../../../../Theme/icons/folderOpenIcon';

import {
  AcademicCapIcon,
  ArrowUpOnSquareIcon,
  BookOpenIcon,
  BriefcaseIcon,
  ClipboardDocumentListIcon,
  CommandLineIcon,
  DocumentDuplicateIcon,
  EllipsisHorizontalIcon,
  InformationCircleIcon,
  NewspaperIcon,
  PencilIcon as PencilIconSolid,
  PhotoIcon,
  RectangleStackIcon,
  SparklesIcon,
  TrashIcon as TrashIconSolid,
  UserIcon,
} from '@heroicons/react/24/solid';

// MUI
import { Menu, MenuItem, useMediaQuery } from '@mui/material';
// Store
import { useCreateTemplateFolder } from 'Components/Chat/CreateTemplateFolder/store';
import useChatStore from 'Components/Chat/store';
import useCreateTemplatesStore from 'Features/CreateTemplatesForm/Store';
import useTreeDNDTemplatesStore from 'Features/FoldersTemplates/Store';
// Components
import useThemeStore from 'Theme/store';
import { cn } from 'utils/styles';

const CustomNode = ({ isOpen, ...props }) => {
  const { droppable, text, id, data } = props.node;
  const {
    onClickTemplate,
    onEditTemplate,
    onDeleteTemplate,
    // onShareTemplate,
    onDuplicateTemplate,
  } = props;
  // Zustand store
  const [selectedChat] = useChatStore((state) => [state.selectedChat]);

  const { setSelectedComponent } = useCreateTemplatesStore();

  const indent = props.depth * 24;

  const handleToggle = (e) => {
    e.stopPropagation();
    props.onToggle(props.node.id);
  };

  const onClick = (e) => {
    handleToggle(e);
    if (!droppable) {
      onClickTemplate(data);
    }
  };

  const { setDeleteFolderModal, setSelectedFolder } =
    useTreeDNDTemplatesStore();
  const { setOpen: setEditModal } = useCreateTemplateFolder();

  // onEdit folder
  const onEditFolder = (e) => {
    e.stopPropagation();
    setEditModal(true);
    setSelectedFolder(data);
  };
  // onDelete folder
  const onDeleteFolder = (e) => {
    e.stopPropagation();
    setDeleteFolderModal(true);
    setSelectedFolder(data);
  };

  const isMobile = useMediaQuery('(max-width: 768px)');

  const newFoldersTemplates = [
    // 'Discretion advised',
    // 'Product research',
    // 'Hiring',
    // 'Role playing',
    // 'Research',
    // 'Business',
    // 'For fun',
    // 'Career Development',
    // 'Image Prompting',
    // 'Image Generation',
  ].map((item) => item?.toUpperCase());

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (e) => {
    e.stopPropagation();
    setAnchorEl(null);
  };

  const showFolderActions = useMemo(() => {
    if (
      text?.toLowerCase() === 'my templates' ||
      text?.toLowerCase() === 'shared with me'
    ) {
      return false;
    }
    if (data?.type === 'folder' && Boolean(data?.creator)) {
      return true;
    }
    return false;
  }, []);

  const { theme } = useThemeStore();

  const getToolIconByCategory = () => {
    let key = data.type !== 'folder' ? data.category : null;
    switch (key) {
      case 'Image Generation':
        return <PhotoIcon className="w-4 h-4" />;
      case 'Writing':
        return <ClipboardDocumentListIcon className="w-4 h-4" />;
      case 'Resources':
        return <RectangleStackIcon className="w-4 h-4" />;
      case 'Business':
        return <BriefcaseIcon className="w-4 h-4" />;
      case 'Career Development':
        return <AcademicCapIcon className="w-4 h-4" />;
      case 'Content':
        return <NewspaperIcon className="w-4 h-4" />;
      case 'Programming':
        return <CommandLineIcon className="w-4 h-4" />;
      case 'Image Prompting':
        return <SparklesIcon className="w-4 h-4" />;
      default:
        return <BookOpenIcon className="w-4 h-4" />;
    }
  };

  return (
    <>
      <div
        className="group tree-node flex items-center max-h-[72px] box-border w-full cursor-pointer font-figtree"
        style={{ paddingInlineStart: indent }}
      >
        <div
          onClick={onClick}
          className={`flex items-center justify-between py-[5px] px-[6px] w-full text-sm ${
            theme == 'dark' ? 'text-palladium' : 'text-agate-violet'
          }`}
        >
          <div
            className={cn(
              'h-6 flex items-center gap-2 py-[2px] px-[6px] w-full group-hover:bg-platinum dark:group-hover:bg-night-black group-hover:rounded',
              {
                ' bg-lavender rounded':
                  selectedChat?.chat?._id === id && !droppable,
              }
            )}
          >
            <div
              className="block md:hidden cursor-grabbing drag-handle col-span-1"
              ref={isMobile ? props.handleRef : null}
            >
              <Bars2Icon className="w-5 h-5 cursor-grabbing" />
            </div>
            <div>
              {!droppable ? (
                <>{getToolIconByCategory()}</>
              ) : (
                <>
                  {isOpen ? (
                    <FolderOpenIcon className="w-4 h-4" />
                  ) : (
                    <FolderIcon className="w-4 h-4" />
                  )}
                </>
              )}
            </div>
            <div className="flex items-center w-[90%] overflow-hidden">
              <div className="flex flex-col gap-2 w-fit line-clamp-1 overflow-hidden text-ellipsis whitespace-nowrap">
                <p
                  id={
                    text === 'Image Generation'
                      ? 'straico-userguiding-category-image'
                      : text === 'Writing'
                      ? 'straico-userguiding-category-writing'
                      : text === 'My Templates'
                      ? 'straico-userguiding-category-mytemplates'
                      : data?.id_userguiding
                      ? data.id_userguiding
                      : ''
                  }
                  className={`text-sm line-clamp-1 ${
                    !droppable
                      ? 'line-height-[1.2] text-pretty' +
                        (selectedChat?.chat?._id === id ? ' font-bold ' : '')
                      : 'font-[500] truncate' + (isOpen ? ' font-bold' : '')
                  }`}
                >
                  {text === 'My Tools'
                    ? 'My Templates'
                    : text === 'Share With Me'
                    ? 'Shared with me'
                    : text}{' '}
                </p>
              </div>
              {droppable &&
                ['image generation', 'image prompting'].includes(
                  data?.name?.toLowerCase()
                ) && (
                  <div className="ml-2">
                    <PhotoIcon className="w-4 h-4 text-violet-blue" />
                  </div>
                )}
              {droppable && data?.creator && !data?.public && (
                <div className="ml-2">
                  <UserIcon className="w-4 h-4 text-violet-blue" />
                </div>
              )}
              {droppable &&
                newFoldersTemplates.includes(text?.toUpperCase()) && (
                  <span className="text-white bg-nue-blue leading-4 uppercase rounded-sm ml-1 text-[10px] font-bold px-1">
                    New!
                  </span>
                )}
            </div>
            {/* Actions component */}
            {droppable ? (
              <>
                {showFolderActions && (
                  <div className="col-span-3 lg:hidden flex-[0.2] lg:group-hover:flex flex gap-[4px] items-center transition-opacity duration-300 ease-in-out">
                    <div onClick={onEditFolder}>
                      <PencilIcon className="w-4 h-4" />
                    </div>
                    <div onClick={onDeleteFolder}>
                      <TrashIcon className="w-4 h-4" />
                    </div>
                  </div>
                )}
              </>
            ) : (
              <>
                <div className="flex items-center gap-[3px] transition-opacity duration-300 ease-in-out">
                  <div onClick={handleClick}>
                    <EllipsisHorizontalIcon className="w-5 h-5" />
                  </div>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    sx={{
                      '& .MuiMenuItem-root': {
                        fontSize: '12px !important',
                        fontFamily: 'Barlow !important',
                      },
                    }}
                  >
                    <MenuItem
                      onClick={(e) => {
                        e.stopPropagation();
                        onClickTemplate(data);
                        handleClose(e);
                      }}
                    >
                      <InformationCircleIcon className="w-4 h-4 mr-1" />
                      See info
                    </MenuItem>
                    <MenuItem
                      onClick={(e) => {
                        e.stopPropagation();
                        onClickTemplate(data);
                        handleClose(e);
                      }}
                    >
                      <ArrowUpOnSquareIcon className="w-4 h-4 mr-1" />
                      Share
                    </MenuItem>
                    <MenuItem
                      onClick={(e) => {
                        e.stopPropagation();
                        onDuplicateTemplate(data);
                      }}
                    >
                      <DocumentDuplicateIcon className="w-4 h-4 mr-1" />
                      Duplicate
                    </MenuItem>
                    {!data?.public && (
                      <MenuItem
                        onClick={(e) => {
                          e.stopPropagation();
                          onEditTemplate(data);
                          setSelectedComponent('edit');
                          handleClose(e);
                        }}
                      >
                        <PencilIconSolid className="w-4 h-4 mr-1" /> Edit
                      </MenuItem>
                    )}
                    {!data?.public && (
                      <MenuItem
                        onClick={(e) => {
                          e.stopPropagation();
                          onDeleteTemplate(data);
                          handleClose(e);
                        }}
                      >
                        <TrashIconSolid className="w-4 h-4 mr-1" /> Delete
                      </MenuItem>
                    )}
                  </Menu>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomNode;
