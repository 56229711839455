/* eslint-disable no-console */
import React, { useCallback, useEffect, useState } from 'react';
// React DOM
import { useLocation } from 'react-router-dom/dist';
// Mobx
import { inject, observer } from 'mobx-react';
// Store
import useTemplateModalStore from './store';
// Icons
import {
  EyeIcon,
  LinkIcon,
  PaperClipIcon,
  StarIcon,
  VariableIcon,
  XMarkIcon,
} from '@heroicons/react/20/solid';
import {
  ArrowDownOnSquareIcon,
  ArrowLeftCircleIcon,
  BoltIcon,
  ChevronLeftIcon,
  GlobeAltIcon,
  LockClosedIcon,
  LockOpenIcon,
  PaperAirplaneIcon,
  RectangleGroupIcon,
  RocketLaunchIcon,
  SparklesIcon,
  StarIcon as StarOutlineIcon,
  UserCircleIcon,
} from '@heroicons/react/24/outline';
import Tooltip from '@mui/material/Tooltip';
import StraicoSymbolIcon from 'Theme/icons/straicoSymbol';
// Utils
import { toast } from 'react-hot-toast';
import { cn } from 'utils/styles';
import { validateObjectHasEmptyFields } from './utils/functions';
// MUI
import { LoadingButton } from '@mui/lab';
import { Button } from '@mui/material';
// Context
// RHF
import { FormProvider, useForm, useWatch } from 'react-hook-form';
// Config
import config from 'config';
// Theme
import useThemeStore from 'Theme/store';
// Emoji
import Emoji from 'react-emojis';
// Components
import AvatarSvgIcon from '@straico-ui/commons/AvatarSvgIcon';
import { buildDataToFormEdit } from 'Components/Chat/TemplateModal/components/AdvancedSection/helpers';
import ShareInput from 'Components/Common/ShareInput/ShareInput';
import Tab from 'Components/Common/Tab';
import Loader from 'Components/Loader';
import CommonModal from 'Features/CreateTemplatesForm/Components/CommonModal';
import ModalConfirmClose from 'Features/CreateTemplatesForm/Components/ModalConfirmClose';
import useCreateTemplatesStore from 'Features/CreateTemplatesForm/Store';
import useClipboardComponent from 'Hooks/useClipboardComponent';
import { formatNumberLikeSocialMedia } from 'utils/commons';
import usePromptLibraryStore from '../PromptLibrary/store';
import useShareLinkTool from '../PromptModal/store';
import AdvancedSection from './components/AdvancedSection';
import AttachmentsSection from './components/AttachmentsSection';
import ErrorModal from './components/ErrorModal';
import VariableSection from './components/VariableSection';
import ViewPromptSection from './components/ViewPromptSection';

const TemplateModal = ({
  store,
  handleTemplateModel,
  onClose,
  handleSubmitModal,
}) => {
  // Store
  const {
    templateData,
    openModal,
    setOpenModal,
    tab: currentTab,
    setTab,
    setErrorModal,
    setErrorModalReason,
    setModels,
    confirmCloseModal,
    setConfirmCloseModal,
    setIsTemplatePrompt,
    templateDataLoading,
  } = useTemplateModalStore();
  //Get models
  const getModels = useCallback(async () => {
    try {
      const response = await store.api.get(`/model`);
      const data = await response.data;
      let transformedModels = data.models;
      setModels(transformedModels);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  }, []);

  useEffect(() => {
    getModels();
  }, []);
  // Modal
  const handleCloseClickOutside = () => {
    setConfirmCloseModal(true);
  };
  const handleClose = () => {
    onClose(defaultParams);
    setOpenModal(false);
    resetForm();
    setShowShare(false);
    setSendDirect(false);
  };

  const handleConfirmClose = () => {
    setConfirmCloseModal(false);
    onClose(defaultParams);
    setOpenModal(false);
    resetForm();
    setShowShare(false);
    setSendDirect(false);
  };

  const handleCancelConfirmClose = () => {
    setConfirmCloseModal(false);
  };

  // Tab
  const [blockTabs, setBlockTabs] = React.useState([]);
  const handleChangeTab = (value) => {
    setTab(value);
    setShowShare(false);
  };

  useEffect(() => {
    if (templateData) {
      // Block attachments tab if no attachments
      if (
        templateData?.fields?.filter((i) =>
          ['image', 'attachment'].includes(i.type)
        ).length === 0 &&
        templateData?.files?.length === 0
      ) {
        setBlockTabs((prev) => [...prev, 'Attachments']);
      }
    } else {
      setBlockTabs((prev) => prev.filter((i) => i !== 'Attachments'));
    }
  }, [templateData]);

  const TABS = [
    {
      icon: <VariableIcon className="w-6 h-6" />,
      text: 'Variables',
      value: 1,
      render: () => (
        <VariableSection
          fields={templateData?.fields}
          runningSample={runningSample}
          openModal={openModal}
        />
      ),
    },
    {
      icon: <PaperClipIcon className="w-6 h-6" />,
      text: 'Attachments',
      value: 2,
      render: () => <AttachmentsSection />,
    },
    {
      icon: <SparklesIcon className="w-6 h-6 -scale-x-100" />,
      text: 'Advanced',
      value: 3,
      render: () => <AdvancedSection closeTemplateModal={handleClose} />,
    },
    {
      icon: <EyeIcon className="w-6 h-6" />,
      text: 'View prompt',
      value: 4,
      render: () => (
        <ViewPromptSection
          selectedTool={templateData}
          promptValue={templateData?.prompt_values?.value}
          formValues={watch()}
        />
      ),
    },
  ];
  // Tabs filter
  const tabs = React.useMemo(() => {
    return TABS.filter((i) => !blockTabs.includes(i.text));
  }, [blockTabs]);

  // Global form state
  const methods = useForm({
    mode: 'all',
  });

  const { handleSubmit, reset, watch, setValue } = methods;
  const { reset: resetForm } = useForm();

  useEffect(() => {
    reset();
    setTab(1);
  }, [openModal]);

  const location = useLocation();
  const [defaultParams, setDefaultParams] = useState({});

  // Reset form default values from URL params
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    // object params
    const paramsObj = Object.fromEntries(params);
    delete paramsObj['selectedTool'];
    delete paramsObj['chat'];
    delete paramsObj['fpr'];

    setDefaultParams(paramsObj);
    reset(paramsObj);
  }, [location, tabs]);

  const handleInsert = (data) => {
    setIsTemplatePrompt(true);
    const attachments = templateData?.fields?.filter((i) =>
      ['image', 'attachment'].includes(i.type)
    );

    // Delete for data the keys that exist in the attachments
    attachments?.forEach((attachment) => {
      delete data[attachment.attr];
    });

    // conditions to pass the data
    const emptyFields = validateObjectHasEmptyFields(data);
    const emptyAttachments =
      attachments?.length > 0 &&
      Object.values(watch('files') ?? {}).length === 0;

    if (emptyFields || emptyAttachments) {
      setErrorModal(true);
      setErrorModalReason({
        emptyFields,
        emptyAttachments,
      });
    } else {
      handleSendData();
    }
  };

  const handleSendData = (runSampleAuto) => {
    //CHECK IF THE USERS CAN USE PREMIUM MODELS
    if (store.profile.plan === 'freeTos' && !templateData.use_tool) {
      toast.success(
        "Looks like that template is using a premium model, but we're switching gears to GPT-3.5 Turbo! 🚀 Or, you could subscribe to a plan and unlock new powers in Straico! ⚡️",
        {
          duration: 8000,
        }
      );
    } else {
      if (templateData?.multi_default_models.length > 0) {
        const modelIds = templateData.multi_default_models.map(
          (model) => model._id
        );
        handleTemplateModel(modelIds);
      } else if (
        templateData?.default_model &&
        Object.keys(templateData?.default_model).length > 0
      ) {
        const modelIds = templateData?.default_model?.id
          ? [templateData.default_model.id]
          : [];
        handleTemplateModel([modelIds]);
      }
    }

    let files = Object.entries(watch('files') ?? {}).map(([, value]) =>
      convertFile(value)
    );
    files = [...files, ...(templateData?.files ?? [])];
    handleSubmitModal(
      Object.entries(watch()),
      templateData,
      files,
      runSampleAuto
    );

    handleClose();
  };

  const convertFile = (file) => {
    return { file: file._id, enabled: true, metadata: { ...file } };
  };

  // Share
  const [showShare, setShowShare] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const { copy } = useClipboardComponent();

  const { setShareLink, shareLink } = useShareLinkTool();

  const onShareTool = async () => {
    try {
      // Copy to clipboard
      let url = `${config.communityUrl}/${templateData?.creator?.hero_alias}/template/${templateData?.slug}`;
      if (store.profile.nickname_fpr)
        url = `${url}?fpr=${store.profile.nickname_fpr}`;

      setShareLink(url);
      copy(url);

      toast.success(
        'All set! The template link has been saved to your clipboard for easy sharing.',
        {
          duration: 10000,
        }
      );
      setShowShare(true);
    } catch (error) {
      toast.error('Error sharing tool');
    } finally {
      toast.dismiss('loading-share-tool');
      setLoading(false);
    }
  };

  const checkExamplesInPrompt = () => {
    let hasSample = false;
    templateData?.fields?.forEach((prompt) => {
      if (prompt.example !== '') {
        hasSample = true;
      }
    });
    return hasSample;
  };

  const [runningSample, setRunningSample] = useState(false);
  const watchedValues = useWatch({ control: methods.control });

  const [runSampleVisible, setRunSampleVisible] = useState(
    checkExamplesInPrompt()
  );

  useEffect(() => {
    const hasValues = Object.values(watchedValues).some(
      (value) => value !== ''
    );
    const hasExamples = checkExamplesInPrompt();
    if (hasExamples) {
      setRunSampleVisible(!hasValues);
    }
  }, [watchedValues]);

  useEffect(() => {
    templateData?.fields?.forEach((prompt) => {
      setValue(prompt.attr, '');
    });
  }, [templateData]);

  useEffect(() => {
    const hasExamples = checkExamplesInPrompt();
    setRunSampleVisible(hasExamples);
  }, [templateData]);

  const onRunSample = async () => {
    //TODO para correr el ejemplo
    try {
      setIsTemplatePrompt(true);
      const modelIdsArray = templateData?.default_model?.id
        ? [templateData.default_model.id]
        : [];

      handleTemplateModel(modelIdsArray);

      templateData?.fields?.forEach((prompt) => {
        setValue(prompt.attr, prompt.example);
      });
      setRunningSample(true);
      setTimeout(() => {
        handleSendData(true);
      }, 1000);
      setIsTemplatePrompt(false);
      return 'Sample values have been set.';
    } catch (error) {
      return 'Error setting sample values.';
    }
  };

  const [sendDirect, setSendDirect] = useState(false);

  const onSendDirectly = async (data) => {
    const attachments = templateData.fields?.filter((i) =>
      ['image', 'attachment'].includes(i.type)
    );
    setIsTemplatePrompt(true);

    // Delete for data the keys that exist in the attachments
    attachments?.forEach((attachment) => {
      delete data[attachment.attr];
    });

    // conditions to pass the data
    const emptyFields = validateObjectHasEmptyFields(data);
    const emptyAttachments =
      attachments?.length > 0 &&
      Object.values(watch('files') ?? {}).length === 0;
    // handleSendData(true);
    if (emptyFields || emptyAttachments) {
      setSendDirect(true);
      setErrorModal(true);
      setErrorModalReason({
        emptyFields,
        emptyAttachments,
      });
    } else {
      handleSendData(true);
    }
    setIsTemplatePrompt(false);
  };

  const { theme } = useThemeStore();

  const veriftyEmoji = (tool) => {
    let iconTag = 'high-voltage';
    if (tool?.icon) {
      if (tool.icon !== ' ') {
        iconTag = tool.icon;
      }
    }
    return <Emoji emoji={iconTag} />;
  };
  const [favTool, setFavTool] = useState(false);

  useEffect(() => {
    if (templateData) {
      setFavTool(templateData.favorite);
    }
  }, [templateData]);

  const onClickFav = async () => {
    const profile = JSON.parse(localStorage.getItem('profile'));

    if (!templateData || !profile) {
      toast.error('Invalid operation');
      return;
    }

    const isCurrentlyFavored = favTool;
    const endpoint = isCurrentlyFavored
      ? '/tool/remove-user-tool-fav'
      : '/tool/add-user-tool-fav';
    const method = isCurrentlyFavored ? 'put' : 'post';

    // Optimistically toggle the favorite for a smooth UX
    setFavTool(!isCurrentlyFavored);

    const postData = {
      userId: profile._id,
      toolId: templateData._id,
    };

    try {
      method === 'put'
        ? await store.api.put(endpoint, postData)
        : await store.api.post(endpoint, postData);

      toast.success(
        `Template ${
          isCurrentlyFavored ? 'removed from' : 'added to'
        } your favorites`
      );
    } catch (error) {
      console.error('Failed to update favorites:', error);
      // Revert the toggle on error
      setFavTool(isCurrentlyFavored);
      toast.error('Failed to update favorites');
    }
  };

  //------------------ Go back to library ------------------
  const [goBackLibrary, setGoBackLibrary] = useState(false);
  const { setOpenPromptLibrary } = usePromptLibraryStore();
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    if (params.get('from') === 'promptLibrary') {
      setGoBackLibrary(true);
    } else {
      setGoBackLibrary(false);
    }
  }, [location]);
  const handleGoBackLibrary = () => {
    setOpenPromptLibrary(true);
    onClose();
  };
  //-----------------------------------------------------

  // Make public template
  const {
    setDefaultData,
    setEdit,
    setStep,
    setOpenModal: setOpenEditModal,
  } = useCreateTemplatesStore();
  const goToEditFormTemplate = () => {
    // Generate data to edit the template
    const data = buildDataToFormEdit(templateData);
    // Function to set the data and open the modal
    setDefaultData(data);
    setEdit(true);
    setStep(3);
    setOpenEditModal(true);
    onClose();
  };

  const onGoToPublicView = () => {
    window.open(
      `${config.communityUrl}/${templateData?.creator?.hero_alias}/template/${templateData?.slug}`,
      '_blank'
    );
  };

  const onGoToPublicProfile = () => {
    window.open(
      `${config.communityUrl}/${templateData?.creator?.hero_alias}`,
      '_blank'
    );
  };

  return (
    <>
      <CommonModal
        open={openModal}
        handleClose={handleCloseClickOutside}
        maxWidth="sm"
        withCloseButton
      >
        {templateDataLoading ? (
          <div className="h-full flex items-center justify-center p-8">
            <Loader />
          </div>
        ) : (
          <FormProvider {...methods}>
            <div
              className={cn(
                'font-figtree min-w-[350px] md:min-w-[500px] flex-1 flex flex-col overflow-auto',
                {
                  'text-raisin-black': theme === 'light',
                  'text-crystal-bell': theme === 'dark',
                }
              )}
            >
              <Tooltip title="Close">
                <div className="hover:bg-ghost-white absolute top-2 right-2 rounded-full">
                  <XMarkIcon
                    className="w-4 h-4 cursor-pointer text-cool-gray"
                    onClick={handleCloseClickOutside}
                  />
                </div>
              </Tooltip>

              <div>
                {goBackLibrary && (
                  <Button
                    variant="inherit"
                    size="small"
                    onClick={handleGoBackLibrary}
                    startIcon={<ArrowLeftCircleIcon className="w-6 h-6" />}
                    sx={{
                      padding: '0px',
                    }}
                  >
                    Prompt library
                  </Button>
                )}
              </div>

              {/* DATA creator */}
              {templateData?.creator?.hero_alias &&
                templateData?.creator?.catch_phrase &&
                templateData?.public && (
                  <div className={cn('mb-5 flex flex-col w-full')}>
                    <div className="flex items-end gap-2 mb-3">
                      {templateData?.creator?.avatar?.svg ? (
                        <AvatarSvgIcon
                          onClick={onGoToPublicProfile}
                          svg={templateData?.creator?.avatar?.svg}
                          className="size-12 cursor-pointer"
                        />
                      ) : (
                        <>
                          {templateData?.creator?.hero_alias !== 'Straico' ? (
                            <UserCircleIcon
                              onClick={onGoToPublicProfile}
                              className="size-12 -mb-1 text-cool-gray dark:text-battleship-gray cursor-pointer"
                            />
                          ) : (
                            <StraicoSymbolIcon
                              onClick={onGoToPublicProfile}
                              className="size-10 -mb-1 rounded-full fill-nue-blue bg-white cursor-pointer"
                            />
                          )}
                        </>
                      )}
                      <a
                        className={cn(
                          'text-xl font-semibold cursor-pointer hover:text-nue-blue'
                        )}
                        href={`${config.communityUrl}/${templateData?.creator?.hero_alias}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {templateData?.creator?.hero_alias}
                      </a>
                    </div>
                    <div
                      className={cn('flex items-center gap-2 text-[14px]', {
                        'text-cool-gray': theme === 'light',
                        'text-crystal-bell': theme === 'dark',
                      })}
                    >
                      <p className="flex items-center gap-2">
                        {templateData?.creator?.catch_phrase}
                      </p>
                      <p className="flex items-center gap-2">
                        <RectangleGroupIcon className="size-5" />
                        {templateData?.creator?.public_tools} Public templates
                      </p>
                    </div>
                  </div>
                )}

              {/* Header */}
              <div className="mb-5">
                <div
                  className={cn('gap-2 justify-between items-center w-full')}
                >
                  <p className="line-clamp-1 font-bold text-sm text-agate-violet dark:text-palladium">
                    Prompt Template
                  </p>
                </div>

                {/* Tool data */}
                <div className="flex items-center gap-2 text-nue-blue dark:text-stargate-shimmer">
                  <div className="text-3xl">{veriftyEmoji(templateData)}</div>
                  <div className="flex items-center gap-2">
                    <div
                      onClick={
                        templateData?.public ? onGoToPublicView : undefined
                      }
                      className={cn(
                        `text-xl leading-[30px] font-bold w-full truncate text-pretty`,
                        {
                          'cursor-pointer': templateData?.public,
                        }
                      )}
                    >
                      {templateData?.title}
                    </div>
                    <Tooltip
                      placement="top"
                      title={
                        templateData?.public
                          ? 'Public template'
                          : 'Private template'
                      }
                    >
                      {templateData?.public ? (
                        <GlobeAltIcon
                          onClick={
                            templateData?.public ? onGoToPublicView : undefined
                          }
                          className={cn(
                            'size-5 text-cool-gray dark:text-battleship-gray',
                            {
                              'cursor-pointer': templateData?.public,
                            }
                          )}
                        />
                      ) : (
                        <LockClosedIcon className="size-5 text-cool-gray dark:text-battleship-gray" />
                      )}
                    </Tooltip>
                  </div>
                  <div className="flex-1" />
                  <div
                    className="rounded-md cursor-pointer"
                    onClick={onClickFav}
                  >
                    <Tooltip
                      title={
                        favTool ? 'Remove from favorites' : 'Add to favorites'
                      }
                      arrow
                      placement="right-start"
                    >
                      {favTool && (
                        <StarIcon className="w-[30px] h-[30px] text-yellow-400" />
                      )}
                      {!favTool && (
                        <StarOutlineIcon className="w-[30px] h-[30px]" />
                      )}
                    </Tooltip>
                  </div>
                </div>

                {/* Models */}
                <div className="w-full flex flex-row justify-between items-center gap-1">
                  <div
                    className={cn(
                      'text-xs uppercase font-bold text-cool-gray dark:text-battleship-gray mt-2 flex flex-wrap',
                      {
                        'w-3/4': templateData?.multi_default_models.length > 2,
                        'w-full':
                          templateData?.multi_default_models.length <= 2,
                      }
                    )}
                  >
                    {templateData?.multi_default_models.length > 0 ? (
                      <div className="flex gap-1 flex-wrap">
                        {templateData?.multi_default_models.map(
                          (model, index) => (
                            <div
                              key={index}
                              className="bg-platinum dark:bg-night-black px-2 rounded"
                              style={{
                                display: 'inline-block',
                                width: 'fit-content',
                                whiteSpace: 'nowrap',
                              }}
                            >
                              {model.name}
                            </div>
                          )
                        )}
                      </div>
                    ) : templateData?.default_model ? (
                      <div>
                        {templateData?.default_model &&
                          Object.keys(templateData?.default_model).length >
                            0 && (
                            <div
                              className="bg-platinum dark:bg-night-black px-2 rounded"
                              style={{
                                display: 'inline-block',
                                width: 'fit-content',
                              }}
                            >
                              {templateData?.default_model?.name}
                            </div>
                          )}
                      </div>
                    ) : (
                      <div>{/* Add content here if needed */}</div>
                    )}
                  </div>
                  <div className="flex gap-1 h-full content-start text-agate-violet items-center">
                    {templateData?.favs != null && (
                      <div className="flex items-start flex-row gap-1">
                        <StarOutlineIcon className="size-3" />
                        <p className=" text-[10px] font-bold min-w-[65px]">
                          {formatNumberLikeSocialMedia(templateData?.favs ?? 0)}{' '}
                          FAVS
                        </p>
                      </div>
                    )}
                    {templateData?.runs != null && (
                      <div className="flex items-start flex-row gap-1">
                        <RocketLaunchIcon className="size-3" />
                        <p className="text-[10px] font-bold min-w-[65px]">
                          {formatNumberLikeSocialMedia(templateData?.runs ?? 0)}{' '}
                          RUNS
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              {/* Description */}
              <div className="text-base text-raisin-black dark:text-crystal-bell mb-5">
                {templateData?.desc}
              </div>

              {showShare ? (
                <>
                  <hr className="mb-4 bg-platinum dark:bg-night-black" />
                  <div>
                    {shareLink && (
                      <div className="mb-5">
                        <ShareInput
                          title="Share Template"
                          description="Anyone with the URL will be able to see the template’s prompt and attachments, save it and use it in their own account."
                          shareLink={shareLink}
                        />
                      </div>
                    )}
                    <div className="">
                      <a
                        className="text-nue-blue dark:text-stargate-shimmer cursor-pointer"
                        href="https://straico.com/affiliate/"
                        target="__blank"
                      >
                        Become an Affiliate!
                      </a>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  {/* Tabs buttons */}
                  <div className="flex justify-center items-center gap-4">
                    {tabs.map((tab, index) => (
                      <Tab
                        key={index}
                        icon={tab.icon}
                        text={tab.text}
                        value={tab.value}
                        currentValue={currentTab}
                        onClick={handleChangeTab}
                      />
                    ))}
                  </div>
                  <hr className="mt-4 bg-platinum dark:bg-night-black" />

                  {/* Tabs content */}
                  <div
                    className={`mt-4 overflow-auto flex-1 duration-500 ease-in-out overflow-rtl ${
                      theme == 'dark'
                        ? 'overflow-rtl-dark'
                        : 'overflow-rtl-light'
                    }`}
                  >
                    {TABS.find((tab) => tab.value === currentTab)?.render()}
                  </div>
                </>
              )}

              {/* buttons */}
              <div className="flex gap-4 pt-5">
                {showShare ? (
                  <LoadingButton
                    variant="filled"
                    color="inherit"
                    startIcon={<ChevronLeftIcon className="w-4 h-4 " />}
                    onClick={() => setShowShare(false)}
                  >
                    Back to template
                  </LoadingButton>
                ) : (
                  <>
                    {templateData?.public &&
                    templateData?.slug &&
                    templateData?.creator?.hero_alias ? (
                      <LoadingButton
                        variant="filled"
                        color="inherit"
                        startIcon={<LinkIcon className="w-4 h-4" />}
                        loading={loading}
                        onClick={onShareTool}
                      >
                        Copy public URL
                      </LoadingButton>
                    ) : templateData?.creator?._id === store.profile._id ? (
                      <Button
                        variant="filled"
                        color="inherit"
                        startIcon={<LockOpenIcon className="w-4 h-4" />}
                        onClick={goToEditFormTemplate}
                      >
                        Make template public
                      </Button>
                    ) : (
                      <></>
                    )}
                  </>
                )}
                <div className="flex-1"></div>
                {runSampleVisible && (
                  <LoadingButton
                    variant="filled"
                    color="inherit"
                    startIcon={<BoltIcon className="w-4 h-4" />}
                    onClick={handleSubmit(onRunSample)}
                  >
                    Run sample
                  </LoadingButton>
                )}
                {!runSampleVisible && !templateData?.use_tool && (
                  <LoadingButton
                    variant="filled"
                    color="inherit"
                    startIcon={<PaperAirplaneIcon className="w-4 h-4" />}
                    onClick={handleSubmit(onSendDirectly)}
                  >
                    Run
                  </LoadingButton>
                )}
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit(handleInsert)}
                  startIcon={<ArrowDownOnSquareIcon className="w-4 h-4" />}
                >
                  Insert
                </Button>
              </div>
            </div>
          </FormProvider>
        )}
      </CommonModal>
      <ErrorModal handleConfirm={handleSendData} sendDirect={sendDirect} />
      <ModalConfirmClose
        open={confirmCloseModal}
        onCancel={handleCancelConfirmClose}
        onClose={handleConfirmClose}
        withCloseButton
      />
    </>
  );
};

export default inject('store')(observer(TemplateModal));
